.cv{
    &__contact{
        position: fixed;
        right: 15px;
        bottom: 20px;
        width: 90px;
        z-index: 3;
        transition: 0.6s;
        transform: scale(.85);
        mix-blend-mode: difference;
        @media (min-width: 769px) {
            right: 30px;
            bottom: 30px;
            width: 130px;
        }
        &.active{
            bottom: 80px;
            @media (min-width: 769px) {
                bottom: 120px;
            }
        }
    }
    &__contents{
        margin-top: 20px;
        @media (min-width: 769px) {
            margin-top: 45px;
        }
        a{
            &:hover{
                .anim{
                    transform: scale(1.2);
                }
            }
        }
    }
    &__foot{
        position: absolute;
        right: 15px;
        bottom: 20px;
        width: 100px;
        z-index: 3;
        @media (min-width: 769px) {
            right: 30px;
            bottom: 30px;
            width: 147px;
        }
    }
}