.header{
    &__navi{
        background-color: #000;
        position: fixed;
        top: 0;
        left: 0;
        width: 50px;
        height: 100%;
        z-index: 3;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 25px 0;
        @media (max-width: 768px) {
            width: 40px;
        }
        &--logo{
            margin: auto;
            width: 30px;
            position: relative;
            z-index: 1;
            @media (max-width: 768px) {
                display: none;
            }
        }
        &--inner{
            background: #000;
            transition: all 0.6s;
            position: fixed;
            z-index: 1;
            top: 0;
            left: -120%;
            width: calc(50% + 24px);
            height: 100%;
            min-width: 708px;
            @media (max-width: 768px) {
                min-width: auto;
                width: 100%;
            }
            &.panelactive{
                left: 0;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
            }
        }
        &--close{
            position: absolute;
            right: 0;
            width: 24px;
            height: 100%;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            img{
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
        }
    }
    &__menu{
        padding: 0 66px 80px 60px;
        @media (min-width: 769px) {
            padding: 0 106px 80px 72px;
        }
    }
    &__button{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        img{
            width: 24px;
        }
    }
}