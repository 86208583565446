@charset "UTF-8";

:root{
    --color-primary: #e8eeef;
    --color-green: #64ff50;
    --bg-yellow: #ffe61e;
    --bg-pink: #ff6e6e;
    --bg-violet: #be29a0;
    --bg-red: #ff3200;
    --bg-gray: #84909f;
    --bg-blue: #0000e6;
    --bg-blue-pale: #0064ff;
    --bg-orange: #fb8023;
    --bg-green: #64ff50;
    --bg-green-deep: #00e12c;
    // --bg-gradient: linear-gradient(var(--start-color), var(--middle-color), var(--end-color));
}

@mixin margin0{
    &:first-child{
        margin-top: 0;
    }
    &:last-child{
        margin-bottom: 0;
    }
}

@import "loading-screen";
@import "flip-screen";

/* vendor */
@import "swiper-bundle.min";

/* js */
@import "js-inview";
@import "js-loop";

/* common */
@import "base";
@import "font_extend";
@import "layout";
@import "container";
@import "section";
@import "header";
@import "footer";
@import "animation";

/* page */
@import "page";
@import "article";
@import "page-top";
@import "page-business";
@import "page-consultant";
@import "page-whoweare";
@import "page-journal";
@import "page-recruit";
@import "page-contact";
@import "page-privacy";
@import "page-notfound";

/* parts */
@import "parts-bg";
@import "parts-box";
@import "parts-br";
@import "parts-button";
@import "parts-card";
@import "parts-cv";
@import "parts-icon";
@import "parts-list";
@import "parts-name";
@import "parts-navi";
@import "parts-para";
@import "parts-pager";
@import "parts-referral";
@import "parts-svg";
@import "parts-table";
@import "parts-tag";
@import "parts-text";
@import "parts-title";

/* scope */
#kv-anime{
    width: calc(100% - 40px);
    left: 40px;
    @media (min-width: 769px) {
        width: calc(100% - 50px);
        left: 50px;
    }
}

@for $i from 1 through 30 {
    .card__mbr:nth-child(#{$i}) .card__mbr--thumb img{transition: clip-path .4s ease-out #{$i * .2}s;}
}



.consul-image-slide{
    position: relative;
    aspect-ratio: 1 / 1;
    &__item{
        position: absolute;
        top: 0;left: 0;
        width: 100%;
        transition: clip-path .3s ease-in;
        clip-path: inset(0 0 0 0);
        .img{
            width: 100%;
            max-width: none;
            aspect-ratio: 1 / 1;
        }
        .block{
            display: block;
            position: absolute;
            z-index: 2;
            top: 0;left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--theme-color);
            transition: clip-path .4s ease-in 1s;
            clip-path: inset(0 0 0 0);
        }
        .name{
            position: absolute;
            z-index: 1;
            left: .5em;
            bottom: .5em;
            font-family: acumin-pro,sans-serif;
            font-weight: 600;
            font-style: normal;
            line-height: 1;
            font-size: 10vw;
            mix-blend-mode: difference;
            // color: var(--theme-color);
            color: #fff;
            @media (min-width:769px){
                font-size: 4vw;
            }
            // @media (min-width:1470px){
            //     font-size: 60px;
            // }
        }
        &.prev{
            z-index: 2;
            clip-path: inset(0 100% 0 0);
            .block{ clip-path: inset(0 100% 0 0); }
        }
        &.active{
            z-index: 1;
            clip-path: inset(0 0 0 0);
            .block{ clip-path: inset(0 100% 0 0); }
        }
        &.next{
            clip-path: inset(0 0 0 0);
            .block{ clip-path: inset(0 0 0 0); }
        }
    }
}
