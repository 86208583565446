.tag{
    &__item{
        // font-size: 0.785rem;
        font-family: acumin-pro, noto-sans-cjk-jp, sans-serif;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0.02em;
        display: inline-block;
        @media (min-width: 769px) {
            // font-size: 0.916rem;
        }
        a{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 22px;
            line-height: 1;
            padding: 1px 14px 2px 10px;
            background-color: #fff;
            border: 1px solid #000;
            border-radius: 3em;
            line-height: 1;
            transition : all 0.3s;
            &:hover{
                background-color: #000;
                color: #fff;
            }
            @media (min-width: 769px) {
                height: 40px;
                padding: 2px 40px 4px 25px;
            }
            span{
                @extend %font_thin;
                font-size: calc(0.785rem * 0.95);
                transform-origin: top center;
                white-space: nowrap;
                @media (min-width: 769px) {
                    font-size: calc(0.916rem * 0.95);
                }
            }
        }
    }
}