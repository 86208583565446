.page-privacy{
    padding: 0 20px 0;
    @media (min-width: 769px) {
        padding: 0 100px 0;
    }
    &__terms{
        max-width: 985px;
        .heading--md{
            @extend %font_ja_b;
            margin: 2em 0 .25em;
            +.para{
                margin-top: .25em;
            }
        }
        .para{
            @extend %font_ja_r;
            font-size: calc(1rem * 0.95);
            margin-top: 2em;
            text-align: justify;
            &:first-child{margin-top: 0;}
            &:last-child{margin-bottom: 0;}
        }
    }
}