.title{
    &--sec{
        line-height: 0.78;
        .en{
            @extend %font_en_b;
            letter-spacing: -0.05em;
            display: block;
        }
        .jp{
            @extend %font_ja_r;
            font-size: calc(0.857rem * 0.95);
            display: block;
            margin-top: 1em;
            @media (min-width: 769px) {
                font-size: calc(0.833rem * 0.95);
            }
        }
    }
    &--vertical{
        @extend %font_ja_b;
        letter-spacing: -.05em;
        white-space: nowrap;
        writing-mode: vertical-rl;
    }
    &--ja{
        @extend %font_ja_m;
        font-size: calc(0.857rem * 0.95);
        @media (min-width: 769px) {
            font-size: calc(1rem * 0.95);
        }
    }
    &--md-en{
        @extend %font_en_b;
        font-size: 1.642rem;
        letter-spacing: -0.05em;
        @media (min-width: 769px) {
            font-size: 1.916rem;
        }
    }
    &--line{
        @extend %font_ja_b;
        font-size: calc(1.285rem * 0.95);
        display: flex;
        align-items: center;
        padding: 1em 25px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 1em 0;
        @media (min-width: 769px) {
            font-size: calc(1.416rem * 0.95);
            padding: 1em 50px;
        }
        .form__lane &{
            margin-top: 0;
            margin-bottom: 0;
            padding: 0 1em 0 0;
            position: relative;
            display: flex;
            align-items: center;
            min-height: auto;
            border: none;
            margin-bottom: 1em;
            @media (min-width: 769px) {
                width: 310/907*100+%;
            }
        }
    }
    &--tag{
        font-size: 2.5rem;
        font-family: acumin-pro, noto-sans-cjk-jp, sans-serif;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.05em;
        position: relative;
        &:before{
            @extend %font_en_m;
            content: "＃";
            display: inline-block;
        }
        @media (min-width: 769px) {
            font-size: 2.916rem;
        }
    }
}