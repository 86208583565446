.box{
    +.box{
        margin-top: 40px;
        @media (min-width: 769px) {
            margin-top: 130px;
        }
    }
    &--list{
        margin-top: 40px;
        @media (min-width: 769px) {
            margin-top: 150px;
        }
        >div+div{
            margin-top: 40px;
            @media (min-width: 769px) {
                margin-top: 130px;
            }       
        }
    }
    &__heading{
        @extend %font_en_b;
        font-size: clamp(26px,9vw,4.583rem);
        line-height: 1;
        border-bottom: 10px solid #000;
        padding-bottom: 20px;
        letter-spacing: -0.03em;
        @media (max-width: 768px) {
            padding-bottom: 10px;
            border-width: 5px;
        }
        .text--sm{
            @extend %font_ja_m;
            font-size: calc(clamp(12px,3vw,1.666rem) * 0.95);
            display: block;
            margin-top: 0.25em
        }
    }
}