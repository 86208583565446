.anim{
    transform-origin:50% 50%;
    animation: 5s linear infinite anim;
}
@keyframes anim{
    0%{ transform:rotate(0);}
    100%{ transform:rotate(360deg); }
}

.anim-fig{
    transform-origin:50% 50%;
    animation: 10s linear infinite anim-fig;
}
@keyframes anim-fig{
    0%{
        transform:rotate(0);
    }
    100%{
        transform:rotate(360deg);
    }
}