.page-whoweare{
    &-fv{
        &__container{
            position: relative;
            .img{
                position: sticky;
                top:0;
                left: 0;
                width: 100%;
                z-index: -1;
            }
            .img-inner{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                img{
                    position: absolute;
                    top: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: .6s;
                    width: 100%;
                    height: 100vh;
                    object-fit: cover;
                    &:nth-of-type(1){
                        opacity: 1;
                        visibility: visible;
                    }
                    &.active{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            .page-title-whoweare{
                margin-top: -10px;
                margin-left: -5px;
                @media (max-width: 768px) {
                    margin-top: 0;
                    margin-left: -10px;
                    padding-right: 60px;
                }
            }
        }
        &__wrapper{

        }
        &-block{
            position: relative;
            @include margin0;
            &:first-child{
                margin-top: 0;
            }
            .text-inner{
                max-width: 560px;
                margin: 0 0 0 auto;
            }
        }
    }
    &-laed{
        position: relative;
        margin-top: 290px;
        &__inner{
            padding: 0 100px;
            @media (max-width: 768px) {
                padding: 0 20px;
            }
            &--heading{
                @extend %font_ja_b;
                font-size: unquote('min(calc((5.2vw) * .95), calc((2.916rem) * .95))');
                letter-spacing: -0.06em;
                line-height: 1.12;
                margin-bottom: 60px;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((9.6vw) * .95), calc((2.571rem) * .95))');
                    margin-bottom: 40px;
                }
            }
            &--text{
                @extend %font_ja_m;
                font-size: unquote('min(calc((3vw) * .95), calc((1.666rem) * .95))');
                line-height: 1.58;
                letter-spacing: -0.03em;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((5.1vw) * .95), calc((1.428rem) * .95))');
                }
            }
        }
        &__logo{
            text-align: center;
            padding: 100px 30px;
            position: relative;
            @media (min-width: 769px) {
                padding: 300px 100px;
            }
            &:before{
                content: "";
                display: block;
                width: 100%;
                height: 620px;
                background: linear-gradient(0deg,#e8eeef 25%,rgba(255,255,255,0) 100%);
                position: absolute;
                left: 0;
                bottom: -1px;
                z-index: -1;
            }
        }
    }
    &-mes{
        background-color: var(--color-primary);
        position: relative;
        padding: 0 100px 150px;
        @media (max-width: 768px) {
            padding: 0 20px 70px;
        }
        &__inner{
            max-width: 1114px;
            margin: auto;
        }
        &__heading{
            margin-bottom: 60px;
            @media (max-width: 768px) {
                margin-bottom: 20px;
            }
            .en{
                @extend %font_en_b;
                font-size: unquote('min(4.8vw, 30px)');
            }
            .jp{
                @extend %font_ja_m;
                font-size: unquote('min(calc((8vw) * .95), calc((2.25rem) * .95))');
                letter-spacing: -.03em;
                line-height: 1.16;
                display: block;
            }
        }
        &__text{
            .para--text{
                font-size: calc(1rem * .95);
                line-height: 1.66;
            }
            p+p{
                margin-top: 2em;
            }
        }
        &__name{
            @extend %font_ja_m;
            font-size: unquote('min(calc((1rem) * .95), calc((1.25rem) * .95))');
            letter-spacing: -0.01em;
            margin-top: 70px;
            text-align: right;
            @media (max-width: 768px) {
                margin-top: 40px;
            }
        }
        &__ft{
            position: relative;
            overflow: hidden;
            min-height: 610px;
            @media (max-width: 768px) {
                min-height: 360px;
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &-prof{
        &__table{
            .table__item{
                dt{
                    width: 280px;
                    @media (max-width: 768px) {
                        width: 38%;
                    }
                    .txt{
                        @extend %font_ja_m;
                    }
                }
                dd{
                    flex: 1;
                    background-color: var(--color-primary);
                    .txt{
                        @extend %font_ja_r;
                        letter-spacing: 0.02em;
                    }
                }
            }
            .txt{
                font-size: calc(clamp(15px,2.2vw,1.25rem) * .95);
                padding: 1em 45px;
                width: 100%;
                @media (max-width: 450px) {
                    padding: 1em 16px;
                }
            }
        }
    }
    &-access{
        &__map{
            position: relative;
            &:after{
                content: "";
                position: absolute;
                bottom: 5px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #000;
            }
            iframe{
                width: 100%;
                aspect-ratio: 16/9;
                filter:grayscale(100%);-webkit-filter:grayscale(100%)
            }
        }
        .table__list{
            .txt{
                @extend %font_ja_r;
                font-size: calc(clamp(15px,2.2vw,1.25rem) * .95);
            }
        }
    }
}
