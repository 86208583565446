.text{
    &--date{
        @extend %font_en_b;
        font-size: 2.214rem;
        letter-spacing: -0.04em;
        line-height: 1;
        @media (min-width: 769px) {
            font-size: 2.583rem;
        }
    }
    &--desc{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    &--title{
        @extend %font_ja_m;
        font-size: calc(5vw * 0.95);
        line-height: 1.25;
        @media (min-width: 769px) {
            font-size: calc(1.666rem * 0.95);
        }
    }
    &--name{
        line-height: 1;
        .jp{
            @extend %font_ja_m;
            transform-origin: top center;
            text-align: center;
            font-size: unquote('min(calc((2.7vw) * .95), calc((1.5rem) * .95))');
            @media (max-width: 450px) {
                font-size: unquote('min(calc((4.3vw) * .95), calc((1.142rem) * .95))');
            }
        }
        .en{
            @extend %font_en_b;
            font-size: 0.666rem;
            margin-top: 0.5em;
            font-size: clamp(10px, 1.4vw, 14px);
            text-align: center;
        }
    }
    &--vertical{
        @extend %font_en_sb;
        font-size: 12px;
        letter-spacing: -0.05em;
        padding: 6px;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        // width: 100%;
        // max-width: 50px;
        white-space: nowrap;
        background-color: var(--bg-green);
        @media (min-width: 769px) {
            padding: 12px;
            font-size: 22px;
        }
    }
    &--nobreak{
        display: inline-block;
    }
}