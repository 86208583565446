%font_ja_r{
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 400;
    font-style: normal;
    transform: scale(calc(1 / 0.95),1);
    width: calc(100% * 0.95);
    transform-origin: top left;
}
%font_ja_m{
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 500;
    font-style: normal;
    transform: scale(calc(1 / 0.95),1);
    width: calc(100% * 0.95);
    transform-origin: top left;
}
%font_ja_b{
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    font-style: normal;
    transform: scale(calc(1 / 0.95),1);
    width: calc(100% * 0.95);
    transform-origin: top left;
}
%font_en_m{
    font-family: acumin-pro, sans-serif;
    font-weight: 500;
    font-style: normal;
}
%font_en_sb{
    font-family: acumin-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
}
%font_en_b{
    font-family: acumin-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
}
%font_thin{
    transform: scale(calc(1 / 0.95),1);
    width: calc(100% * 0.95);
    transform-origin: top left;
}
%font_thin_n{
    font-size: calc( 24px * 0.95);
    transform: scale(calc(1 / 0.95),1);
    width: calc(100% * 0.95);
    transform-origin: top left;
}