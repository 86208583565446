*,
*:before,
*:after{
    margin: 0;
    padding: 0;
    list-style: none;
    background-repeat: no-repeat;
    box-sizing:inherit;
    vertical-align:inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color:inherit;
    font-kerning: inherit;
    outline: none;
}

html,body{
    height: 100%;
}
html{
    background-color: #e8eeef;
    font-kerning: normal;
    font-feature-settings: 'palt';
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    @media(min-width: 769px){ font-size: 24px; }
}
body{
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.41;
    // position: relative;
    // overflow-x: hidden; //win
}

input[type="text"]:focus, textarea:focus{
    outline: 0;
}
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}
input[type="number"]{
  -webkit-appearance: none;
  border-radius: 0;
  -moz-appearance:textfield;
}
button, input, textarea{
  font-family: inherit;
}
select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  font-family: inherit;
  font-weight: inherit;
}
img{
    max-width: 100%;
    height: auto;
    vertical-align: top;
}
a{
    text-decoration: none;
}
button{
    background-color: transparent;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
a,a img,button{
    outline: none;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
em, i{
    font-style: normal;
}

::selection {
    background: #000;
    color: #fff;
}
