.page-recruit{
    &-fv{
        &__text--wrapper{
            padding: 50px 20px 0;
            @media (min-width: 769px) {
                padding: 380px 100px 0;
            }
        }
        &__title{
            padding: 0 30px 0 0;
            @media (min-width: 769px) {
                padding: 100px 160px 0 0;
            }
        }
        &__text{
            color: var(--color-green);
            @media (min-width: 769px) {
                margin: auto;
            }
            &--copy{
                @extend %font_ja_b;
                font-size: unquote('min(calc((9.1vw) * .95), calc((5.166rem) * .95))');
                line-height: 0.97;
                letter-spacing: -0.1em;
                padding-bottom: 10px;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((10.7vw) * .95), calc((2.857rem) * .95))');
                }
            }
            &--lead{
                margin-top: 30px;
                @media (min-width: 769px) {
                    margin-top: 70px;
                }
            }
        }
        &__bg{
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-section{
        margin-top: 50px;
        @media (min-width: 769px) {
            margin-top: 150px;
        }
        @include margin0;
        .title--sec{
            position: relative;
            margin-bottom: 25px;
            @media (min-width: 769px) {
                margin-bottom: 120px;
            }
            .en{
                font-size: unquote('min(13.2vw, 7.5rem)');
                line-height: 0.77;
                letter-spacing: -0.05em;
                // margin-left: -28px;
                position: relative;
                left: -15px;
                @media (max-width: 768px) {
                    font-size: unquote('min(16vw, 4.285rem)');
                    left: -5px;
                    // margin-left: -5px;
                }
            }
            .jp{
                @extend %font_ja_m;
                font-size: unquote('min(calc((2.7vw) * .95), calc((1.5rem) * .95))');
                letter-spacing: -0.02em;
                line-height: 1.2;
                margin-top: 0.5em;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((4.3vw) * .95), calc((1.142rem) * .95))');
                }
            }
        }
        &--wrapper{
            margin-top: -5px;
            @media (min-width: 769px) {
                margin-top: -14px;
            }
        }
    }
    &-values{
        .title--sec{
            margin-bottom: 25px;
            @media (min-width: 769px) {
                margin-bottom: 120px;
            }
        }
        &__fig{
            padding: 0 25px;
            text-align: center;
            @media (min-width: 769px) {
                padding: 0 50px;
            }
            img{
                max-width: 786px;
                width: 100%;
                margin: auto;
            }
        }
        &__table{
            margin-top: 130px;
            @media (max-width: 768px) {
                margin-top: 40px;
            }
            table,th,td{
                border: 2px solid #000;
                border-collapse: collapse;
                @media (max-width: 768px) {
                    border-width: 1px;
                }
            }
            table{
                @media (max-width: 768px) {
                    border: none
                }
            }
            th,td{
                vertical-align: middle;
                @media (max-width: 768px) {
                    display: block;
                }
            }
            tr:first-child th{
                @media (max-width: 768px) {
                    border-top: 1px solid #000;
                }
            }
            th{
                text-align: left;
                padding: 50px;
                width: 260px;
                background-color: var(--color-green);
                @media (max-width: 768px) {
                    border-bottom: none;
                    border-top: none;
                    padding: 12px 20px 15px;
                    width: 100%;
                }
                .jp{
                    @extend %font_ja_m;
                    font-size: unquote('min(calc((3.7vw) * .95), calc((2.083rem) * .95))');
                    letter-spacing: -0.02em;
                    @media (max-width: 768px) {
                        font-size: unquote('min(calc((6.7vw) * .95), calc((1.785rem) * .95))');
                    }
                }
                .en{
                    @extend %font_en_b;
                    font-size: unquote('min(2.7vw, 0.833rem)');
                    letter-spacing: -0.02em;
                    line-height: 1;
                    display: block;
                }
            }
            td{
                padding: 25px 45px;
                @media (max-width: 768px) {
                    padding: 20px;
                }
            }
        }
    }
    &-mind{
        &__list{
            border-bottom: 2px solid #000;
            @media (max-width: 768px) {
                border-width: 1px;
            }
            .headline{
                @extend %font_ja_b;
                font-size: unquote('min(calc((5.2vw) * .95), calc((2.958rem) * .95))');
                letter-spacing: -0.02em;
                display: flex;
                align-items: center;
                padding: 25px 20px 32px 160px;
                border-top: 2px solid #000;
                border-bottom: 2px solid #000;
                background-color: var(--bg-green);
                position: relative;
                line-height: 1.03;
                @media (max-width: 1000px) {
                    padding: 25px 20px 32px 110px;
                }
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((7vw) * .95), calc((1.857rem) * .95))');
                    padding: 11px 20px 13px 55px;
                    border-width: 1px;
                }
                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    transform: translateY(-50%);
                    left: -5px;
                    width: 86px;
                    height: 58px;
                    background-size: contain;
                    @media (max-width: 768px) {
                        left: -1px;
                        width: 40px;
                        height: 27px;
                    }
                }
                &.num1{
                    &:before{
                        background-image: url(../img/icon_num01_sp.svg);
                    }
                }
                &.num2{
                    &:before{
                        background-image: url(../img/icon_num02_sp.svg);
                    }
                }
                &.num3{
                    &:before{
                        background-image: url(../img/icon_num03_sp.svg);
                    }
                }
                &.num4{
                    &:before{
                        background-image: url(../img/icon_num04_sp.svg);
                    }
                }
            }
            .title{
                position: relative;
                padding: 5px 0 5px 65px;
                @media (max-width: 768px) {
                    padding: 5px 0 5px 20px;
                }
                &:before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1px;
                    height: 100%;
                    background-color: #000;
                    display: inline-block;
                }
            }
            .text{
                padding: 20px;
                @media (min-width: 769px) {
                    padding: 20px 100px 20px 235px;
                }
            }
        }
    }
    &-training{

    }
    &-work{

    }
    &-welfare{
        table{
            @extend %font_ja_m;
            tr:nth-child(2n+1){
                background-color: var(--bg-green);
            }
        }
        table , td{
            border: 2px solid #000;
            border-collapse: collapse;
            @media (max-width: 768px) {
                border-width: 1px;
            }
        }
        td{
            width: 50%;
            text-align: center;
            padding: 1em 10px;
            vertical-align: middle;
            @media (min-width: 769px) {
                padding: 30px;
            }
            span{
                font-size: unquote('min(calc((3vw) * .95), calc((1.666rem) * .95))');
                letter-spacing: -0.02em;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((4.3vw) * .95), calc((1.285rem) * .95))');
                }
            }
        }
    }
    &-future{
        padding: 0 20px;
        &--container{
            max-width: 1120px;
            margin: auto;
        }
        &__fig{
            margin: 0 auto 30px;
            text-align: center;
        }
        &__block{
            display: flex;
            justify-content: space-between;
            overflow-x: auto;
            position: relative;
            &--item{
                width: calc(100% / 4);
                padding: 0 25px 5px;
                border-right: 1px solid #b3b3b3;
                &:first-child{
                    border-left: 1px solid #b3b3b3;
                }
                @media (max-width: 768px) {
                    padding: 0 8px 5px;
                }
                .list--bullets{
                    font-size: 18px;
                    margin-top: 60px;
                    li{font-feature-settings: initial;}
                    @media (max-width: 768px) {
                        font-size: 10px;
                        margin-top: 20px;
                    }
                }
                @media (max-width: 768px) {
                    min-width: 140px;
                }
            }
            .fig{
                display: flex;
                justify-content: center;
                align-items: flex-end;
                @media (max-width: 768px) {
                    img{
                        width: 56px;
                    }
                }
            }
        }
        .drag{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: auto;
            width: 110px;
            height: 110px;
            transition: opacity 0.3s;
            @media (min-width: 640px) {
                display: none;
            }
            &.active{
                opacity: 0;
            }
        }
        &__disc{
            margin-top: 70px;
            @media (max-width: 768px) {
                margin-top: 35px;
            }
            .para--sm{
                font-size: unquote('min(calc((1.6vw) * .95), calc((0.916rem) * .95))');
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((3.2vw) * .95), calc((0.857rem) * .95))');
                }
            }
        }
    }
    &-info{
        .page-cv-card{
            &__container{
                border-top: 40px solid #000;
                border-bottom: 40px solid #000;
                @media (max-width: 768px) {
                    grid-template-columns: repeat(1, 1fr);
                    border-width: 14px;
                }
            }
            &__item{
                border-width: 1px;
                margin-bottom: -1px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 130px;
                @media (max-width: 768px) {
                    min-height: 68px;
                }
                &:hover{
                    // .button--frame{
                    //     color: #fff;
                    //     background-color: #000;
                    // }
                    // .svg--dark{
                    //     fill: #fff;
                    // }
                    .icon--arrow{
                        animation: arrow 1.2s infinite;
                    }
                }
            }
            &__item--heading{
                background-color: var(--bg-green);
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                min-height: auto;
                height: 100%;
                padding: 15px;
                margin: auto;
                border-right: 1px solid #000;
                padding: 20px 50px;
                @media (max-width: 768px) {
                    padding: 20px;
                }
                .title{
                    @extend %font_ja_m;
                    transform-origin: top center;
                    font-size: unquote('min(calc((3vw) * .95), calc((1.666rem) * .95))');
                    text-align: left;
                    @media (max-width: 768px) {
                        font-size: unquote('min(calc((4.8vw) * .95), calc((1.285rem) * .95))');
                        margin: 0 auto 0 0;
                        transform-origin: top left;
                    }
                }
                .industry{
                    display: inline-block;
                }
            }
            &__item--btn{
                background-color: #fff;
                border-width: 1px;
                display: flex;
                align-items: center;
                width: 38%;
                max-width: 436px;
                min-width: 125px;
                border-top: none;
                height: 100%;
                a{
                    min-height: 65px;
                    height: 100%;
                }
                .button--frame{
                    font-size: unquote('min(3.4vw, 46px)');
                    min-height: auto;
                    height: 100%;
                    position: relative;
                    @media (max-width: 768px) {
                        font-size: unquote('min(5.4vw, 20px)');
                    }
                }
                .icon--arrow{
                    position: absolute;
                    right: 1em;
                }
                @keyframes arrow{
                    0%{
                        right: .6em;
                        opacity: 0;
                    }
                    50%{
                        right: .4em;
                        opacity: 1;
                    }
                    100%{
                        right: 0;
                        opacity: 0;
                    }
                }
            }
        }
    }
    &-media{
        &__slide{
            background-color: var(--bg-green);
            padding: 0;
            border: 1px solid #000;
            font-size: 0;
            @media (min-width: 769px) {
                padding: 0 100px;
            }
            &--inner{
                max-width: 1120px;
                margin: auto;
                border-right: 1px solid #000;
                border-left: 1px solid #000;
                background-color: var(--color-primary);
                @media (max-width: 768px) {
                    border: none;
                }
            }
            .speakerdeck-iframe{
                width: 100%;
                aspect-ratio: 16/9;
                border-radius: 0 !important;
            }
        }
        .page-cv-card{
            &__container{
                margin-top: -1px;
                grid-template-columns: repeat(2, 1fr);
            }
            &__item{
                border-width: 1px;
            }
            &__item--heading{
                padding: 150px 0 60px;
                justify-content: flex-start;
                min-height: 468px;
                @media (max-width: 768px) {
                    padding: 50px 0 20px;
                    min-height: 170px;
                }
                .title{
                    padding: 0 15px;
                    width: 100%;
                    text-align: center;
                }
                .note{
                    margin: auto;
                    line-height: 1.1;
                }
                i{
                    display: block;
                    line-height: 1;
                    margin-bottom: 120px;
                    @media (max-width: 768px) {
                        margin-bottom: 32px;
                    }
                }
            }
            &__item--btn{
                border-width: 1px;
                @media (max-width: 768px) {
                    .button--frame{min-height: 65px;}
                }
            }
        }
        .js-mask{
            @include js-fadein;
            &:before{
                background-color: #fff;
            }
        }
    }
    &-cv{
        &__entry{
            &--inner{
                max-width: 1214px;
                width: 100%;
                margin: auto;
                position: relative;
                padding-right: 75px;
                @media (min-width: 769px) {
                    padding-right: 150px;
                }
                &:after{
                    background: url(../img/arrow_fill.svg) no-repeat center;
                    background-size: contain;
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 75px;
                    height: 24px;
                    margin: auto;
                    @media (min-width: 769px) {   
                        width: 130px;
                        height: 68px;
                    }
                }
            }
        }
        &__entry-foot{
            padding-top: 135px;
        }
        &__entry,&__entry-foot{
            .page-recruit-cv__entry--inner{
                &:after{
                    position: absolute;
                    right: 0.5em;
                }
            }
            &:hover{
                .page-recruit-cv__entry--inner{
                    &:after{
                        animation: arrow 1.2s infinite;
                    }
                }
            }
            @keyframes arrow{
                0%{
                    opacity: 0;
                }
                50%{
                    right: .2em;
                    opacity: 1;
                }
                100%{
                    right: -.4em;
                    opacity: 0;
                }
            }
        }
    }
    &__box{
        padding: 0 25px;
        @media (min-width: 769px) {
            padding: 0 50px;
        }
    }
}

.is-in{
    fill: #fff;
}