.icon{
    &--arrow,&--target{
        // transition: all 0.6s;
    }
    &--arrow{
        width: 63px;
        @media (max-width: 768px) {
            width: 22px;
        }
    }
    &--target{
        width: 52px;
        @media (max-width: 768px) {
            width: 18px;
        }
    }
}