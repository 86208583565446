.br{
    &-sp{
        display: none;
        .page-recruit-welfare &{
            @media (max-width: 520px) {
                display: inline;
            }
        }
        .page-business-second__intro--text &,.referral__table &,.referral &{
            @media (max-width: 768px) {
                display: inline;
            }
        }
        .page-recruit-mind__list--item &{
            @media (max-width: 500px) {
                display: inline;
            }
        }
        .consultant__lead &,.feature__list &,.page-whoweare-prof__table &,.page-whoweare-laed__inner--heading &{
            @media (max-width: 450px) {
                display: inline;
            }
        }
    }
    &-pc{
        display: inline;
        .feature__list &{
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}