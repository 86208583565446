.para{
    &--text{
        @extend %font_ja_r;
        letter-spacing: 0.02em;
        line-height: 1.4;
        text-align: justify;
    }
    /* 22px */
    &--sm{
        @extend %font_ja_r;
        font-size: calc(clamp(14px,3.5vw,0.916rem) * 0.95);
        letter-spacing: 0.02em;
        line-height: 1.54;
        text-align: justify;
    }
    &--md{
        @extend %font_ja_r;
        font-size: calc(0.8702rem);
        line-height: 1.54;
        letter-spacing: 0.02em;
        text-align: justify;
    }
    /* 28px */
    &--reg{
        @extend %font_ja_r;
        font-size: calc(clamp(14px,3.5vw,1.166rem) * 0.95);
        line-height: 1.21;
        letter-spacing: -0.03em;
        vertical-align: baseline;
        text-align: justify;
    }
    /* 28px */
    &--med{
        @extend %font_ja_m;
        font-size: calc(clamp(14px,2.5vw,1.166rem) * 0.95);
        line-height: 1.41;
        letter-spacing: -0.03em;
        vertical-align: baseline;
        text-align: justify;
    }
    /* 38px */
    &--lg{
        @extend %font_ja_r;
        font-size: calc(clamp(14px,3.5vw,1.583rem) * 0.95);
        line-height: 1.23;
        letter-spacing: -0.03em;
        text-align: justify;
    }
}