.table{
    // @extend %font_ja_r;
    // font-size: calc(1.25rem * 0.95);
    // @media (min-width: 769px) {
    //     font-size: calc(1.25rem * 0.95);
    // }
    &__item{
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #000;
        &:last-child{border: none;}
        dt{
            @extend %font_ja_m;
            background-color: #fff;
            border-right: 1px solid #000;
        }
        dd{
            display: flex;
            align-items: center;
            flex: 1;
        }
    }
    &__list{
        li{
            padding: 1em 45px;
            border-bottom: 1px solid #000;
            @media (max-width: 450px) {
                padding: 1em;
            }
        }
    }
}