.page-business{
    position: relative;
    .en{
        @extend %font_en_b;
        font-size: unquote('min(2.6vw, 25px)');
        display: block;
    }
    .jp{
        @extend %font_ja_b;
        font-size: calc(clamp(24px,5vw,2.25rem) * 0.95);
        line-height: 1.1;
        display: block;
    }
    .para--reg{
        line-height: 1.41;
    }
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        content: "";
        width: 50%;
        height: 100%;
        background : linear-gradient(0deg, rgba(100, 255, 80, 1) 0%, rgba(75, 229, 117, 1) 13.77%, rgba(12, 163, 212, 1) 44.34%, rgba(0, 150, 230, 1) 50%, rgba(0, 2, 230, 1) 99.17%, rgba(0, 0, 230, 1) 99.82%);
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    &:after{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        content: "";
        width: 1px;
        height: 100%;
        background: #000;
        @media (max-width: 768px) {
            content: none;
        }
    }
    &__fv{
        display: flex;
        position: relative;
        overflow: hidden;
        z-index: 1;
        .loop-word{
            width: 270px;
            height: 100%;
            max-height: 640px;
            position: relative;
            @media (max-width: 768px) {
                width: 70px;
                // max-height: none;
            }
            // &:before{
            //     content: "";
            //     position: relative;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            //     background-color: #555;
            // }
        }
        .fv-container{
            flex: 1;
            position: relative;
            padding: 170px 100px 120px;
            @media (max-width: 768px) {
                padding: 40px 20px 145px;
            }
            &__bg{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }
        }
        .fv-title{
            margin-bottom: 40px;
            @media (max-width: 768px) {
                margin-bottom: 20px;
            }
            .en{
                display: block;
            }
        }
        .fv-fig{
            margin-top: 340px;
            @media (max-width: 768px) {
                margin-top: 35px;
            }
        }
    }
    &-contents{
        &__flex{
            display: flex;
            .en,.jp{
                @media (max-width: 768px) {
                    writing-mode: vertical-rl;
                }
            }
            .jp{
                @extend %font_ja_b;
                font-size: calc(clamp(16px,4vw,2.25rem) * 0.95);
                line-height: 1.1;
                @media (max-width: 768px) {
                    letter-spacing: -0.05em;
                }
            }
            .fig{
                margin: 5px auto 0;
                text-align: center;
                &.sp{display: none;}
                @media (max-width: 768px) {
                    &.sp{display: block;}
                    max-width: 440px;
                }
            }
        }
        &__sp{
            padding: 25px 0;
            @media (min-width: 769px) {
                display: none;
            }
            .en{
                font-size: unquote('min(3.2vw, 20px)');
            }
            .jp{
                font-size: calc(clamp(24px,7.2vw,2.25rem) * 0.95);
            }
        }
        &__heading{
            width: 50%;
            padding: 115px 0;
            position: relative;
            @media (max-width: 768px) {
                // width: 70px;
                width: 20px;
                // padding: 20px 0;
            }
            &--title{
                position: sticky;
                top: 100px;
                left: 0;
                padding: 0 4% 0 50px;
                @media (max-width: 768px) {
                    padding: 0px 20px 0 40px;
                    top: 20px;
                    .page-business-contents__flex &{
                        // display: flex;
                        // justify-content: center;
                        // flex-direction: row-reverse;
                        // br{display: none;}
                        display: none;
                    }
                }
                &:before{
                    position: absolute;
                    content: "";
                    background-color: #000;
                    width: 38px;
                    height: 18px;
                    top: 8px;
                    left: 0;
                    @media (max-width: 768px) {
                        width: 30px;
                        height: 8px;
                        top: 4px;
                    }
                }
                .fig{
                    display: inline-block;
                    margin-top: 35px;
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
        &__disc{
            background-color: var(--color-primary);
            width: 50%;
            padding: 150px 50px 40px;
            &.last{
                padding-bottom: 140px;
                @media (max-width: 768px) {
                    padding-bottom: 30px;
                }
            }
            @media (max-width: 768px) {
                padding: 20px;
                width: 100%;
            }
            p+p{
                margin-top: 2em;
                @media (max-width: 768px) {
                    margin-top: 1.5em;
                }
            }
            .fig+p{
                @media (min-width: 769px) {
                    margin-top: 0;
                }
            }
        }
    }
    &-info{
        position: relative;
        z-index: 1;
        .page-cv-card{
            &__container{
                @media (max-width: 768px) {
                    grid-template-columns: repeat(1,1fr);
                }
            }
            &__item{
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: -1px;
                }
                &--heading{
                    min-height: 300px;
                    padding: 15px 20px;
                    text-align: center;
                    @media (max-width: 768px) {
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex: 1;
                        min-height: auto;
                        height: 100%;
                        margin: auto;
                        text-align: left;
                        background-color: var(--color-primary);
                        border-right: 1px solid #000;
                    }
                }
                &--btn{
                    background-size: cover;
                    background-position: center right;
                    background-repeat: no-repeat;
                    color: #fff;
                    @media (max-width: 768px) {
                        display: flex;
                        align-items: center;
                        width: 38%;
                        min-width: 128px;
                        border-top: none;
                        height: 100%;
                    }
                    &.btn-maker{
                        background-color: #0044c8;
                    }
                    &.btn-construction{
                        background-color: #00b441;
                    }
                    &.btn-it{
                        background-color: #4a667a;
                    }
                    .button--frame{
                        font-size: unquote('min(3.8vw, 46px)');
                        min-height: 122px;
                        @media (max-width: 768px) {
                            min-height: 68px;
                            font-size: unquote('min(5.4vw, 20px)');
                        }
                    }
                    .svg--dark{
                        fill: #fff;
                        position: absolute;
                        right: .5em;
                        @media (max-width: 768px) {
                            width: 20px;
                        }
                    }
                    a{
                        padding: 0 50px;
                        @media (max-width: 1000px) {
                            min-height: 65px;
                            height: 100%;
                            padding: 0 20px;
                        }
                    }
                }
                &:hover{
                    .svg--dark{
                        animation: arrow 1.2s infinite;
                    }
                }
                @keyframes arrow{
                    0%{
                        opacity: 0;
                    }
                    50%{
                        right: .2em;
                        opacity: 1;
                    }
                    100%{
                        right: -.4em;
                        opacity: 0;
                    }
                }
            }
        }
        .lg{
            @extend %font_ja_m;
            font-size: unquote('min(calc((3.3vw) * .95), calc((1.666rem) * .95))');
            transform-origin: top center;
            @media (max-width: 768px) {
                font-size: unquote('min(calc((4.8vw) * .95), calc((1.428rem) * .95))');
                transform-origin: top left;
            }
        }
        .sm{
            @extend %font_en_b;
            font-size: unquote('min(1.6vw, 20px)');
            display: block;
            letter-spacing: -.03em;
            @media (max-width: 768px) {
                font-size: 10px;
            }
        }
    }
    .section-img-mid{
        min-height: 120px;
        position: relative;
        z-index: 1;
        // background-image: url(../img/business_bg.jpg);
        // background-attachment: fixed;
        // background-size: cover;
        // background-position: bottom;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom;
            z-index: -1;
        }
        @media (max-width: 768px) {
            min-height: 60px;
        }
    }
    &-second{
        &__fv{
            margin-bottom: 135px;
            @media (max-width: 768px) {
                margin-bottom: 70px;
            }
            .fv-title{
                margin: 100px 0 50px 60px;
                @media (max-width: 768px) {
                    margin: 110px 0 100px -4px;
                }
            }
            .en{
                @extend %font_en_b;
                font-size: unquote('min(9.6vw, 5.416rem)');
                line-height: 0.8;
                letter-spacing: -0.03em;
                @media (max-width: 768px) {
                    font-size: unquote('min(10.2vw, 3rem)');
                }
            }
            .jp{
                @extend %font_ja_b;
                font-size: unquote('min(calc((4.6vw) * .95), calc((2.625rem) * .95))');
                line-height: 0.95;
                letter-spacing: -0.03;
                display: block;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((4.8vw) * .95), calc((1.285rem) * .95))');
                }
            }
        }
        &__intro{
            padding: 60px 50px;
            min-height: 330px;
            position: relative;
            @media (max-width: 768px) {
                padding: 30px 20px;
                min-height: 292px;
            }
            &--text{
                max-width: 674px;
            }
            .para--med{
                color: #fff;
            }
            .intro-bg{
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__cv{
            &--inner{
                max-width: 1214px;
                width: 100%;
                margin: auto;
                position: relative;
                padding-right: 75px;
                @media (max-width: 768px) {
                    padding-right: 48px;
                }
                &:after{
                    background: url(../img/arrow_wht.svg) no-repeat center;
                    background-size: contain;
                    content: "";
                    position: absolute;
                    right: 0.5em;
                    top: 0;
                    bottom: 0;
                    width: 68px;
                    height: 36px;
                    margin: auto;
                    @media (max-width: 768px) {
                        width: 28px;
                        height: 18px;
                    }
                }
                @keyframes arrow{
                    0%{
                        opacity: 0;
                    }
                    50%{
                        right: .2em;
                        opacity: 1;
                    }
                    100%{
                        right: -.4em;
                        opacity: 0;
                    }
                }
            }
            a:hover{
                .page-business-second__cv--inner{
                    &:after{
                        animation: arrow 1.2s infinite;
                    }
                }
            }
            .note{
                font-size: unquote('min(calc((3.7vw) * .95), calc((0.666rem) * .95))');
                display: block;
                padding-top: 0.5em;
            }
        }
    }
    &-second-content{
        margin-top: 100px;
        @media (max-width: 768px) {
            margin-top: 40px;
        }
        &--wrap{
            padding: 0 50px 0;
            @media (max-width: 768px) {
                padding: 0 20px 0;
            }
        }
        .title-sec{
            .en{
                @extend %font_en_b;
                font-size: unquote('min(7.9vw, 4.458rem)');
                letter-spacing: -0.05em;
                line-height: 0.81;
                display: block;
                @media (max-width: 768px) {
                    font-size: unquote('min(11.5vw, 3.071rem)');
                }
            }
            .jp{
                @extend %font_ja_r;
                font-size: unquote('min(calc((1.5vw) * .95), calc((0.833rem) * .95))');
                letter-spacing: 0.02em;
                display: block;
                margin-top: 8px;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((3.2vw) * .95), calc((0.857rem) * .95))');
                }
            }
        }
        .title-line{
            position: relative;
            margin-bottom: 40px;
            padding-bottom: 10px;
            @media (max-width: 768px) {
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
            .en{
                @extend %font_en_b;
                font-size: unquote('min(1.4vw, 0.75rem)');
                letter-spacing: -0.05em;
                @media (max-width: 768px) {
                    font-size: 0.714rem;
                }
            }
            .jp{
                @extend %font_ja_b;
                font-size: unquote('min(calc((3.7vw) * .95), calc((2.083rem) * .95))');
                line-height: 1.2;
                letter-spacing: -0.03em;
                display: block;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((6.4vw) * .95), calc((1.714rem) * .95))');
                }
            }
            &:after{
                content: "";
                position: absolute;
                left: 0;
                bottom: -20px;
                
                background-size: cover;
                background-position: left;
                width: 100%;
                height: 18px;
                @media (max-width: 768px) {
                    bottom: -5px;
                    height: 9px;
                }
            }
            &.maker{
               &:after{
                    background : linear-gradient(90deg, rgba(0, 68, 200, 1) 0%, rgba(83, 69, 174, 1) 64.4%, rgba(89, 69, 172, 1) 69.29%, rgba(134, 62, 180, 1) 100%);
               } 
            }
            &.it{
               &:after{
                    background : linear-gradient(90deg, rgba(74, 102, 122, 1) 0.55%, rgba(75, 105, 125, 1) 22.55%, rgba(77, 114, 136, 1) 41.44%, rgba(82, 129, 154, 1) 59.14%, rgba(83, 134, 159, 1) 63.37%, rgba(85, 137, 159, 1) 76.19%, rgba(92, 148, 158, 1) 87.21%, rgba(103, 166, 157, 1) 97.53%, rgba(106, 171, 157, 1) 100%);
               } 
            }
            &.construction{
               &:after{
                    background : linear-gradient(90deg, rgba(0, 180, 75, 1) 0.07%, rgba(0, 170, 146, 1) 53.79%, rgba(0, 169, 157, 1) 61.74%, rgba(37, 133, 200, 1) 99.81%);
               }   
            }
        }
        .feature{
            &__list{
                margin-top: 60px;
                @media (max-width: 768px) {
                    margin-top: 30px;
                }
            }
            .list__item{
                margin-top: 90px;
                @include margin0;
                @media (max-width: 768px) {
                    margin-top: 30px;
                }
            }
        }
        .referral{
            margin: 150px auto 0;
            @media (max-width: 768px) {
                margin: 60px auto 0;
            }
            &__note{
                @extend %font_ja_m;
                font-size: calc(18px * 0.95);
                margin-top: 30px;
                @media (max-width: 768px) {
                    margin-top: 15px;
                    font-size: calc(10px * 0.95);
                }
            }
        }
        .trading{
            margin: 140px auto 120px;
            @media (max-width: 768px) {
                margin: 60px auto;
            }
            &__list{
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(276px, 1fr));
                flex-wrap: wrap;
                gap: 34px;
                margin-top: 50px;
                @media (max-width: 768px) {
                    margin-top: 25px;
                    gap: 20px;
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
        .consultant{
            margin: 140px auto　130px;
            @media (max-width: 768px) {
                margin: 40px auto;
            }
            .title-sec{
                margin-bottom: 45px;
                @media (max-width: 768px) {
                    margin-bottom: 15px;
                }
            }
            &__lead{
                max-width: 1166px;
            }
            .para--med{
            }
            .page-consultant{
                margin-top: 40px;
            }
            &-slider{
                margin-top: 40px;
            }
        }
        .journal{
            margin-top: 110px;
            @media (max-width: 768px) {
                margin-top: 45px;
            }
            .title-sec{
                margin-bottom: 45px;
                @media (max-width: 768px) {
                    margin-bottom: 15px;
                }
            }
        }
        &__bg{
            width: 100%;
            height: 150px;
            margin-top: 120px;
            @media (max-width: 768px) {
                height: 50px;
                margin-top: 60px;
            }
        }
    }
    &-other{
        &__heading{
            @include js-fadein;
            &:before{
                background-color: var(--color-primary);
            }
            .en{
                font-size: unquote('min(10.4vw, 107px)');
            }
        }
        &__slider{
            .container--slider{
                display: flex;
                flex-flow: row nowrap;
                width: 100%;
                margin-top: -1px;
            }
        }
    }
}