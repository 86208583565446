.page-top{
    &__fv{
        width: 100%;
        height: 100vh;
        &--heading{
            position: absolute;
            top: 15px;
            right: 15px;
            @media (min-width: 769px) {
                top: 30px;
                right: 45px;
            }
            .page-title-top{
                @extend %font_ja_b;
                font-size: calc( 22px * 0.96);
                transform: scale(calc(1 / 0.96),1);
                width: calc( 100% * 0.96 );
                line-height: 1.18;
                letter-spacing: -.05em;
                writing-mode: vertical-rl;
                @media (min-width: 769px) {
                    font-size: calc( 34px * 0.96);                
                }
                span{
                    display: block;
                }
                &[data-state='standby'] span{
                    transition: clip-path 0ms;
                    clip-path: inset(0 0 100% 0);
                }
                &[data-state='show'] span{
                    &:nth-child(1){transition: clip-path 400ms ease-in;}
                    &:nth-child(2){transition: clip-path 400ms ease-in 300ms;}
                    clip-path: inset(0 0 0 0);
                }
                &[data-state='hide'] span{
                    &:nth-child(1){transition: clip-path 200ms ease-out;}
                    &:nth-child(2){transition: clip-path 200ms ease-out 100ms;}
                    clip-path: inset(100% 0 0 0);
                }
            }
        }
    }
    &-contents{
        background-color: var(--color-primary);
        border-top: 1px solid #000;
        @media (max-width: 768px) {
            border-bottom: 1px solid #000;
        }
        &__container{
            @media (min-width: 769px) {
                display: flex;
                border-bottom: 1px solid #000;
            }
            &--wrapper,&--photo{
                padding: 30px 20px 85px;
                @media (min-width: 769px) {
                    padding: 90px 50px 275px;
                    width: 50%;
                }
            }
            &--wrapper{
                @media (min-width: 769px) {
                    border-right: 1px solid #000;
                    background-color: #fff;
                }
            }
            &--photo{
                background-color: var(--color-primary);
                .page-top-contents__inner{
                    @include js-fadein;
                    &:before{
                        background-color: var(--color-primary);
                    }
                }
                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
        &__inner{
            margin-top: 72px;
            min-height: auto;
            @media (min-width: 769px) {
                min-height: 100vh;
                margin-top: 284px;
                &.longer{min-height: 170vh;}
            }
            &:first-child{margin-top: 0;}
            &--heading{
                line-height: .8;
                margin-bottom: 2em;
            }
            &--sp{
                margin-top: 20px;
                @include js-fadein;
                &:before{
                    background-color: var(--color-primary);
                }
                @media (min-width: 769px) {
                    display: none;
                }
            }
            .num{
                @extend %font_en_b;
                font-size: 2.5rem;
                display: block;
                margin-bottom: 0.15em;
                @media (min-width: 769px) {
                    font-size: 2.916rem;
                }
            }
            .en{
                @extend %font_en_b;
                font-size: unquote('min(16.2vw, 4.285rem)');
                word-wrap: break-word;
                line-height: 0.8;
                letter-spacing: -0.05em;
                @media (min-width: 769px) {
                    font-size: unquote('min(7.2vw, 5rem)');
                }
            }
            .ja{
                @extend %font_ja_m;
                font-size: calc( 1rem * 0.95);
                display: block;
                margin-top: 1em;
            }
            .teams-list{
                li+li{
                    margin-top: 45px;
                    @media (max-width: 768px) {
                        margin-top: 20px;
                    }
                }
                &-text{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 252px;
                    padding: 25px 30px;
                    color: #fff;
                    @media (max-width: 768px) {
                        height: 126px;
                        padding: 12px 15px;
                    }
                    .lg{
                        @extend %font_ja_m;
                        font-size: unquote('min(calc((3.8vw) * .95), calc((2.125rem) * .95))');
                        transform-origin: top left;
                        @media (max-width: 768px) {
                            font-size: unquote('min(calc((6.6vw) * .95), calc((1.714rem) * .95))');
                            line-height: 1.1;
                        }
                    }
                    .sm{
                        @extend %font_en_sb;
                        font-size: unquote('min(2.2vw, 30px)');
                        letter-spacing: -0.03em;
                        line-height: 1.13;
                        @media (max-width: 768px) {
                            font-size: unquote('min(3.8vw, 14px)');
                        }
                    }
                }
            }
        }
        &__text{
            @media (min-width: 769px) {
                position: sticky;
                top: 50px;
                left: 0;
            }
        }
        .crossfade{
            position: relative;
            width: 100%;
            aspect-ratio: 23/25;
            >li{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                transition: clip-path .3s ease-in;
                clip-path: inset(0 0 0 0);
                .fill{
                    position: absolute;
                    top: 0;left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 1;
                    transition: clip-path .4s ease-in 1s;
                    clip-path: inset(0 0 0 0);
                }
                .img{
                    position: absolute;
                    top: 0;left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 0;
                }
                .name{
                    @extend %font_en_b;
                    font-size: unquote('min(3.7vw, 2.083rem)');
                    letter-spacing: -0.04em;
                    line-height: 0.86;
                    display: block;
                    position: absolute;
                    right: 25px;
                    top: 30px;
                    text-align: right;
                    z-index: 2;
                    @media (max-width: 768px) {
                        font-size: unquote('min(8vw, 2.142rem)');
                        right: 15px;
                        top: 12px;
                    }
                }
                &.prev{
                    z-index: 2;
                    clip-path: inset(0 100% 0 0);
                    .fill{
                        clip-path: inset(0 100% 0 0);
                    }
                }
                &.active{
                    z-index: 1;
                    clip-path: inset(0 0 0 0);
                    .fill{
                        clip-path: inset(0 100% 0 0);
                    }
                }
                &.next{
                    clip-path: inset(0 0 0 0);
                    .fill{
                        clip-path: inset(0 0 0 0);
                    }
                }
            }
        }
        // .topSwiper{
        //     .swiper-slide-prev,
        //     .swiper-slide-active,
        //     .swiper-slide-duplicate-active{
        //         .img{
        //             animation: change-img-anim 0.6s linear 1s 1 normal both;
        //         }
        //     }
        //     .photo-box{
        //         position: relative;
        //         .fill{
        //             position: absolute;
        //             width: 100%;
        //             height: auto;
        //         }
        //         .img{
        //             transform: translateX(100%);
        //         }
        //         @keyframes change-img-anim{
        //             0%{
        //                 opacity: 0;
        //             }
        //             100%{
        //                 transform: translateX(0);
        //                 opacity: 1;
        //             }
        //         }
        //         &__name{
        //             @extend %font_en_b;
        //             font-size: unquote('min(3.7vw, 2.083rem)');
        //             letter-spacing: -0.04em;
        //             line-height: 0.86;
        //             position: absolute;
        //             right: 25px;
        //             top: 30px;
        //             text-align: right;
        //             @media (max-width: 768px) {
        //                 font-size: unquote('min(8vw, 2.142rem)');
        //                 right: 15px;
        //                 top: 12px;
        //             }
        //         }
        //     }
        // }
    }
    &-media{
        &__vision{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 300px;
            background-color: #000;
            color: #fff;
            overflow: hidden;
        }
        &__container{
            display: flex;
            flex-wrap: wrap;
            @media (min-width: 951px) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }
        &__item{
            width: 50%;
            border-bottom: 1px solid #000;
            position: relative;
            &:hover{
                .icon--arrow{
                    animation: arrow 1.2s infinite;
                }
            }
            &:first-child{
                width: 100%;
                @media (min-width: 951px) {
                    border-right: none;
                }
            }
            &:nth-child(2){
                border-right: 1px solid #000;
                @media (min-width: 951px) {
                    border-right: none;
                }
            }
            @media (min-width: 951px) {
                border: 1px solid #000;
                margin-left: -1px;
                width: auto;
            }
            &--heading{
                @extend %font_en_b;
                font-size: 2.25rem;
                letter-spacing: -0.04em;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 155px;
                background-color: #fff;
                word-wrap: break-word;
                @media (min-width: 951px) {
                    font-size: 2.625rem;
                    min-height: 512px;
                }
                .js-mask{
                    @include js-fadein;
                    &:before{
                        background-color: #fff;
                    }
                }
            }
            &--btn{
                background-color: var(--color-primary);
                border-top: 1px solid #000;
                position: relative;
                .icon--arrow{
                    position: absolute;
                    right: 45px;
                }
                @keyframes arrow{
                    0%{
                        opacity: 0;
                    }
                    50%{
                        right: .4em;
                        opacity: 1;
                    }
                    100%{
                        right: 0;
                        opacity: 0;
                    }
                }
            }
        }
    }
    &-news{
        &__container{
            @media (min-width: 769px) {
                display: grid;
                grid-template-columns: 200px 1fr;
                .page-loop__vision{
                    display: none;
                }
            }
        }
        &__heading{
            @extend %font_en_b;
            font-size: 5rem;
            color: #fff;
            background-color: #000;
            padding: 50px;
            writing-mode: vertical-rl;
            text-orientation: sideways;
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
                display: none;
            }
            .num{
                font-size: 2.5rem;
                padding-right: 10px;
                position: relative;
                top: -18px;
                @media (min-width: 769px) {
                    font-size: 2.916rem;
                    margin-right: -30px;
                }
            }
            .en{
                padding-bottom: 20px;
            }
            .title--ja{
                display: block;
                @media (min-width: 769px) {
                    display: contents;
                }
            }
        }
        &__parent{
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            background-color: var(--color-primary);
            margin-top: -1px;
            &--item{
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                margin-bottom: -1px;
                height: 100%;
                @media (min-width: 1051px) {
                    display: flex;
                }
                &:nth-child(1){
                    .text--date:before{
                        content: "#001";
                    }
                }
                &:nth-child(2){
                    .text--date:before{
                        content: "#002";
                    }
                }
                &:nth-child(3){
                    .text--date:before{
                        content: "#003";
                    }
                }
                &:nth-child(4){
                    .text--date:before{
                        content: "#004";
                    }
                }
                &:nth-child(5){
                    .text--date:before{
                        content: "#005";
                    }
                }
                &:nth-child(6){
                    .text--date:before{
                        content: "#006";
                    }
                }
                dt,dd{
                    padding: 20px;
                    @media (min-width: 1051px) {
                        padding: 50px 45px;
                    }
                }
                .txt{
                    flex: 1;
                    background-color: var(--color-primary);
                    a{text-decoration: underline;}
                }
            }
            .text--date{
                width: 100%;
                border-bottom: 1px solid #000;
                background-color: #fff;
                @media (min-width: 1051px) {
                    max-width: 458px;
                    border-right: 1px solid #000;
                    border-bottom: none;
                }
                &:before{
                    font-size: 1.666rem;
                    display: block;
                }
            }
        }
    }
}
