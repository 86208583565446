.page-title-body{
    min-height: 318px;
    padding: 100px 20px 110px;
    @media (min-width: 769px) {
        padding: 200px 50px 180px;
        min-height: 492px;
    }
}
.page-title-recruit{
    position: relative;
    z-index: 0;
    padding: 0 0 35px;
    background-color: #000;
    @media (min-width: 769px) {
        padding: 0 0 210px;
    }
}
.page-title-journal{
    padding: 130px 20px 110px 0;
    @media (min-width: 769px) {
        padding: 115px 50px 70px 0;
    }
    .page-title{
        margin-left: -1px;
    }
}
.page-title-journal-article{
    position: relative;
    overflow: hidden;
    &__heading{
        @extend %font_en_b;
        font-size: 5.785rem;
        letter-spacing: -0.03em;
        position: absolute;
        top: -50px;
        left: -5px;
        z-index: 1;
        @media (min-width: 769px) {
            font-size: 7.833rem;
            top: -115px;
            left: -10px;
        }
    }
    &__flex{
        @media (min-width: 769px) {
            display: flex;
            justify-content: space-between;
            min-height: 769px;
        }
        .text{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex-basis: 46%;
            padding: 140px 20px 100px;
            min-height: 317px;
            @media (min-width: 769px) {
                justify-content: center;
                padding: 50px 8%;
            }
        }
        .text--date{
            font-size: 1.25rem;
            margin-bottom: 0.5em;
        }
        .text--desc{
            @extend %font_ja_m;
            font-size: 1.666rem;
            line-height: 1.25;
            -webkit-line-clamp: initial;
        }
        .thumb{
            position: relative;
            padding-top: 100%;
            border-top: 1px solid #000;
            @media (min-width: 769px) {
                flex-basis: 54%;
                padding-top: 0;
                border-top: none;
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
.page-journal-other{
    &__heading{
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 25px 20px;
        margin-top: -1px;
        @media (min-width: 769px) {
            padding: 70px 50px;
        }
        .title--sec{
            max-width: 1214px;
            margin: auto;
        }
    }
}
.page-title-contact{
    min-height: 318px;
    padding: 120px 20px 30px;
    @media (min-width: 769px) {
        padding: 200px 50px 180px;
        min-height: 492px;
    }
}
.page-title-privacy{
    padding: 80px 20px 70px;
    @media (min-width: 769px) {
        padding: 135px 100px 150px;
        min-height: 492px;
    }
}
.page-title-notfound{
    padding: 70px 20px 0;
    @media (min-width: 769px) {
        padding: 120px 100px 0;
        text-align: center;
    }
}
.page{
    &-common{
        &__tags{
            max-width: 1214px;
            margin: auto;
            @media (min-width: 769px) {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
            }
            &--heading{
                display: block;
                margin: 0 0 1em 0;
                @media (min-width: 769px) {
                    margin: -5px 0 0 0;
                }
            }
            .title--md-en{
                line-height: 1;
            }
            .tag{
                flex: 1;
                margin-left: -5px;
                margin-top: -10px;
                @media (min-width: 769px) {
                    padding-left: 2em;
                    margin-top: -25px;
                    margin-left: 0;
                }
                &__item{
                    margin-left: 5px;
                    margin-top: 10px;
                    @media (min-width: 769px) {
                        margin-top: 25px;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    &-title{
        @extend %font_en_b;
        line-height: 0.78;
        @media (min-width: 1001px) {
            font-size: 7.833rem;
        }
        .en{
            font-size: unquote('min(18.2vw, 188px)');
            letter-spacing: -0.03em;
            display: block;
            .page-title-contact &,.page-title-privacy &,.page-title-notfound &{
                font-size: unquote('min(17.2vw, 188px)');
            }
        }
        .jp{
            @extend %font_ja_b;
            display: block;
            margin-top: 1em;
            font-size: calc(clamp(12px,2vw,1rem) * 0.95);
        }
        &__fv{
            &--lead{
                font-size: calc(1rem * 0.95);
                letter-spacing: 0.02em;
                margin-top: 80px;
                text-align: justify;
                @media (min-width: 769px) {
                    font-size: calc(0.916rem * 0.95);
                    max-width: 530px;
                }
                .page-title-notfound &{
                    max-width: 100%;
                    @media (min-width: 769px) {
                        text-align: center;
                    }
                }
            }
            &--note{
                margin-top: 35px;
                @media (min-width: 769px) {
                    margin-top: 100px;
                    text-align: center;
                }
                .para--reg{
                    text-align: center;
                    @media (max-width: 768px) {
                        text-align: left;
                    }
                }
            }
        }
    }
    &-title-recruit{

    }
    &-cv-card{
        &__container{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            .page-recruit-info &{
                grid-template-columns: repeat(1, 1fr);
            }
        }
        &__item{
            border: 1px solid #000;
            margin-left: -1px;
            width: auto;
            position: relative;
            &:hover{
                // .button--frame{
                //     color: #fff;
                //     background-color: #000;
                // }
                // .svg--dark {
                //     fill: #fff;
                // }
            }
            &.disable{
                pointer-events: none;
                position: relative;
                filter: saturate(50%) brightness(80%);
                &:after{
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.2);
                    z-index: 1;
                }
            }
            &--heading{
                @extend %font_en_b;
                font-size: unquote('min(5.2vw, 2.916rem)');
                letter-spacing: -0.04em;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: #fff;
                word-wrap: break-word;
                    min-height: 512px;
                @media (max-width: 950px) {
                    font-size: unquote('min(7vw, 1.928rem)');
                    min-height: 155px;
                }
            }
            &--btn{
                background-color: var(--color-primary);
                border-top: 1px solid #000;
                .button--frame{
                    padding: 10px 45px;
                    @media (max-width: 1000px) {
                        padding: 10px 15px;
                    }
                }
            }
            .note{
                @extend %font_ja_r;
                transform-origin: top center;
                font-size: unquote('min(calc((2.7vw) * .95), calc((0.916rem) * .95))');
                letter-spacing: 0.02em;
                line-height: 1.54;
                text-align: center;
            }
        }
        &__link{
            a{
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &-article-fv{
        position: relative;
        min-height: 318px;
        padding: 190px 40px 250px 20px;
        @media (min-width: 769px) {
            padding: 215px 50px 125px;
            min-height: 492px;
        }
        &__heading{
            margin-bottom: 30px;
            @media (min-width: 769px) {
                margin-bottom: 140px;
            }
        }
        &__shoulder{
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            overflow: hidden;
            @media (max-width: 768px) {
                width: 29px;
            }
        }
        .para--lg {
            font-size: calc(clamp(14px,4.5vw,1.583rem) * .95);
        }
    }
    &-title-article{
        @extend %font_ja_m;
        font-size: calc(clamp(18px,9vw,4.916rem) * 0.95);
        letter-spacing: -0.04em;
        line-height: 1.1;
    }
    &-loop{
        &__vision{
            height: 300px;
            background-color: #000;
            color: #fff;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (max-width: 768px) {
                height: 116px;
            }
            .page-top-media &{
                @media (min-width: 769px) {
                    // padding-bottom: 30px;
                }
            }
            .page-consultant-second--wapper &{
                height: 198px;
                @media (max-width: 768px) {
                    height: 100px;
                }
            }
            .row1 &{
                height: 160px;
                @media (max-width: 768px) {
                    height: 90px;
                }
            }
        }
    }
}