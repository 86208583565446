.svg{
    &--view{
        transition: all 400ms cubic-bezier(0.545, -0.340, 0.490, 1.415);
        // transition-timing-function: cubic-bezier(0.750, 0, 0.490, 1.000);
        &:hover{
            transform: scale(1.25);
        }
    }
    &--wht{
        fill: #fff;
    }
    &--arrow{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}