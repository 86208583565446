.referral{
    &__table{
        position: relative;
        height: auto;
        margin-top: 20px;
        max-height: 400px;
        overflow: hidden;
        transition: max-height 1s;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            transition: 1s;
            background: linear-gradient(
                to bottom,
                transparent 0%,
                #e8eeef 100%
            );
        }
        &.open{
            &:after{
                z-index: -1;
                opacity: 0;
            }
            +.referral__btn{
                .button--open{
                    &:before{
                        transform: scale(-1,1);
                    }
                }
            }
        }
    }
    &__btn{
        max-width: 360px;
        margin: 90px auto 0;
        @media (max-width: 768px) {
            margin: 40px auto 0;
            max-width: 180px;
        }
    }
    table{
        width: 100%;
        max-width: 1215px;
        border: none;
        margin: auto;
        border-collapse: collapse;
        td,th{
            font-weight: 500;
            font-style: normal;
            border: none;
            border-left: 2px solid #cad8da;
            border-top: 2px solid #cad8da;
            width: calc(100% / 3);
            vertical-align: middle;
            text-align: center;
            @media (max-width: 768px) {
                border-width: 1px;
            }
        }
        td:first-child,th:first-child{
            border-left:none;
        }
        tr:first-child td,tr:first-child th{
            border-top:none;
        }
        tr:nth-child(even){
            td:nth-child(odd){
                background-color: #fff;
            }
        }
        tr:nth-child(odd){
            td:nth-child(even){
                background-color: #fff;
            }
        }
        th{
            font-size: unquote('min(1.5vw, 1rem)');
            line-height: 1.2;
            letter-spacing: -0.02em;
            background-color: #000;
            color: var(--color-primary);
            padding: 20px;
            height: 90px;
            @media (max-width: 768px) {
                font-size: unquote('min(3.2vw, 0.857rem)');
                padding: 9px 8px;
                height: 58px;
            }
        }
        td{
            font-size: unquote('min(1.5vw, 0.833rem)');
            letter-spacing: -0.03em;
            line-height: 1.2;
            padding: 20px;
            height: 124px;
            @media (max-width: 768px) {
                font-size: unquote('min(2.6vw, 0.714rem)');
                padding: 5px 6px;
                height: 85px;
            }
            .income{
                vertical-align: baseline;
            }
            strong{
                font-family: sans-serif;
                font-weight: 600;
            }
        }
    }
}