.pager{
    @extend %font_en_b;
    display: inline-block;
    @media (max-width: 768px) {
        font-size: 17px;
    }
    &__inner{    
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
    }
    &__item{
        font-size: 1.4rem;
        line-height: 1;
        display: inline-block;
        text-align: center;
        padding: 8px 0;
        box-sizing: border-box;
        text-decoration: none;
        color: #000;
        position: relative;
        outline: none;
        margin: 10px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        cursor: pointer;
        @media (max-width: 768px) {
            width: 28px;
            height: 28px;
            margin: 3px;
            padding: 2px 0;
            &:nth-child(6){
                display: none;
            }
        }
        &.current{
            pointer-events: none;
            background-color: #000 ;
            color: #fff;
        }
    }
    &-num{
        @media (max-width: 768px) {
            &:nth-child(6){
                display: none;
            }
        }
    }
    .dots{
        font-family: '小塚ゴシック Pro','Kozuka Gothic Pro',sans-serif;
        background-color: transparent;
        border: none;
        pointer-events: none;
        line-height: 1;
    }
    .prev,.next{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 10px;
        background-color: transparent;
        border: none;
        @media (max-width: 768px) {
            margin: 3px;
        }
        &:before{
            content: "";
            background-image: url(../img/arrow.svg);
            background-size: contain;
            display: block;
            width: 48px;
            height: 25px;
            @media (max-width: 768px) {
                width: 24px;
                height: 13px;
            }
        }
    }
    .prev{
        left: 0;
        &:before{
            transform: scaleX(-1);
        }
    }
    .next{
        right: 0;
    }
}

/* wp pager */
.wp-pagenavi{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    .pages,.last{
        display: none;
    }
    .current,.page,.previouspostslink,.nextpostslink,.extend{
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        position: relative;
        width: 56px;
        height: 56px;
        @media (max-width: 768px) {
            width: 28px;
            height: 28px;
        }
    }
    .current,.page{
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 50%;
        transition: background-color .3s;
    }
    .current,.page,.extend{
        font-size: 1.4rem;
        color: #000;
        margin: 10px;
        padding: 8px 0 10px;
        line-height: 1;
        @media (max-width: 768px) {
            margin: 3px;
        }
    }
    .page{
        @media (max-width: 768px) {
            &:nth-child(7){
                display: none;
            }
        }
    }
    .current{
        background-color: #000;
        color: #fff;
    }
    .extend{
        font-family: '小塚ゴシック Pro','Kozuka Gothic Pro',sans-serif;
        background-color: transparent;
        border: none;
        pointer-events: none;
        line-height: 1;
    }
    .page,.previouspostslink,.nextpostslink{
        cursor: pointer;
    }
    .previouspostslink,.nextpostslink{
        text-decoration: none;
        margin: 10px;
        transition: background-color .3s;
        color: transparent;
        @media (max-width: 768px) {
            margin: 3px;
        }
        &:before{
            content: "";
            background-image: url(../img/arrow.svg);
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            background-size: contain;
            display: block;
            width: 48px;
            height: 25px;
            @media (max-width: 768px) {
                width: 24px;
                height: 13px;
            }
        }
    }
    .previouspostslink{
        left: 0;
        &:before{
            transform: scaleX(-1);
        }
    }
    .nextpostslink{
        right: 0;
    }
}