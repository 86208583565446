.page-consultant{
    &__fv{
        position: relative;
        padding: 0px 50px 330px;
        min-height: 764px;
        @media (max-width: 768px) {
            padding: 0 20px 40px 0;
            min-height: 636px;
        }
        &--heading{
            max-width: 567px;
            width: 50%;
            position: relative;
            z-index: 1;
            @media (max-width: 768px) {
                width: 100%;
                position: absolute;
                top: -2px;
                left: -2px;
            }
        }
    }
    &__fvslider{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;
        @media (max-width: 768px){
            .sp{display: block;}
            .pc{display: none;}
        }
        @media (min-width: 769px){
            .sp{display: none;}
            .pc{display: block;}
        }
        li{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            transition: clip-path .3s ease-in;
            clip-path: inset(0 0 0 0);
            &::after{
                content:"";
                display: block;
                position: absolute;
                top: 0;left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--theme-color);
                transition: clip-path .4s ease-in 1s;
                clip-path: inset(0 0 0 0);
            }
            &.prev{
                z-index: 2;
                clip-path: inset(0 100% 0 0);
                &::after{
                    clip-path: inset(0 100% 0 0);
                }
            }
            &.active{
                z-index: 1;
                clip-path: inset(0 0 0 0);
                &::after{
                    clip-path: inset(0 100% 0 0);
                }
            }
            &.next{
                clip-path: inset(0 0 0 0);
                &::after{
                    clip-path: inset(0 0 0 0);
                }
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media (max-width: 768px) {
                    object-position: 65% 50%;
                }
            }
        }
    }
}
.consulSwiper--container{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.consulSwiper{
    width: 100%;
    height: 100%;
}
.page-consultant-second{
    position: relative;
    &--wapper{
        .layout--content{
            padding-left: 50px;
            @media (max-width: 768px) {
                padding-left: 8px;
            }
        }
        .page-loop__vision{
            background-color: transparent;
            color: #000;
        }
        &.gradient-construction{
            background : linear-gradient(0deg, rgba(37, 133, 200, 1) 0.19%, rgba(0, 169, 157, 1) 27.48%, rgba(0, 170, 146, 1) 36.82%, rgba(0, 180, 75, 1) 99.93%);
        }
        &.gradient-it{
            background : linear-gradient(0deg, rgba(106, 171, 157, 1) 0.12%, rgba(103, 166, 157, 1) 2.58%, rgba(92, 148, 158, 1) 12.86%, rgba(85, 137, 159, 1) 23.85%, rgba(83, 134, 159, 1) 36.63%, rgba(82, 129, 154, 1) 40.86%, rgba(77, 114, 136, 1) 58.56%, rgba(75, 105, 125, 1) 77.45%, rgba(74, 102, 122, 1) 99.45%);
        }
        &.gradient-maker{
            background : linear-gradient(0deg, rgba(134, 62, 180, 1) 0%, rgba(89, 69, 172, 1) 26.57%, rgba(83, 69, 174, 1) 31.75%, rgba(0, 68, 200, 1) 100%);
        }
    }
    &__fv{
        position: relative;
        padding: 200px 50px 330px;
        min-height: 400px;
        @media (max-width: 768px) {
            padding: 40px 20px;
            min-height: 636px;
        }
        &:after{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            background-color: var(--theme-color);
            width: 350px;
            height: 100%;
            z-index: -1;
            @media (max-width: 768px) {
                width: 60px;
            }
        }
        &--heading{
            @extend %font_en_b;
            max-width: 640px;
            .sm{
                font-size: 28px;
                letter-spacing: -0.03em;
                display: block;
                font-size: unquote('min(18.2vw, 28px)');
                @media (max-width: 768px) {
                    font-size: unquote('min(18.2vw, 15px)');
                }
            }
            .lg{
                font-size: unquote('min(18.2vw, 110px)');
                letter-spacing: -0.04em;
                line-height: 0.8;
                @media (max-width: 768px) {
                    font-size: unquote('min(13.2vw, 46px)');
                }
            }
        }
        &--bg{
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }
        }
    }
    &--container{
        background-color: var(--color-primary);
    }
    .content__headline{
        padding-bottom: 30px;
        @media (min-width: 1101px) {
            display: flex;
            padding-bottom: 120px;
        } 
        &.profile{
            padding-bottom: 0;
        }
    }
    .content__flex{
        padding-top: 30px;
        @media (min-width: 1101px) {
            display: flex;
            padding-top: 120px;
            &:first-child{padding-top: 100px;}
        }
    }
    &__heading{
        width: 50%;
        padding: 0;
        position: relative;
        @media (max-width: 1100px) {
            width: 100%;
        }
        .profile &{
            padding: 25px 20px 25px 12px;
            @media (min-width: 769px) {
                padding: 70px 45px 40px;
            }
        }
        &--name{
            position: sticky;
            top: 0;
            left: 0;
            .jp{
                @extend %font_ja_m;
                font-size: unquote('min(calc((4.2vw) * .95), calc((2.375rem) * .95))');
                letter-spacing: -0.03em;
                line-height: 1.08;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((10.7vw) * .95), calc((2.857rem) * .95))');
                }
            }
            .en{
                @extend %font_en_sb;
                font-size: unquote('min(18.2vw, 22px)');
                display: block;
                padding-left: 2px;
                @media (max-width: 768px) {
                    font-size: unquote('min(18.2vw, 12px)');
                }
            }
            .tag{
                margin-top: 20px;
                @media (max-width: 768px) {
                    margin-top: 12px;
                }
            }
        }
        &--title{
            position: sticky;
            top: 0;
            left: 0;
            padding: 0 1em 0 50px;
            @media (max-width: 1100px) {
                padding: 0 20px;
            }
            .en{
                @extend %font_en_sb;
                font-size: unquote('min(2.2vw, 22px)');
                display: block;
                @media (max-width: 768px) {
                    font-size: unquote('min(18.2vw, 15px)');
                }
            }
            .jp{
                @extend %font_ja_m;
                font-size: unquote('min(calc((3.7vw) * .95), calc((2.083rem) * .95))');
                letter-spacing: -0.03em;
                line-height: 1.08;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((7vw) * .95), calc((1.857rem) * .95))');
                }
            }
        }
    }
    &__division{
        max-width: 458px;
        @media (max-width: 1100px) {
            max-width: 100%;
        }
        .t-ribbon{
            @extend %font_ja_b;
            font-size: unquote('min(calc((3.7vw) * .95), calc((1.25rem) * .95))');
            letter-spacing: 0.02em;
            line-height: 1.16;
            margin-top: 1em;
            @media (max-width: 768px) {
                font-size: unquote('min(calc((4.5vw) * .95), calc((1.142rem) * .95))');
            }
        }
        .t-division{
            display: flex;
            align-items: center;
            height: 90px;
            border-top: 2px solid #000;
            border-bottom: 2px solid #000;
            @media (max-width: 768px) {
                height: 44px;
                border-width: 1px;
            }
        }
        .t-division--div,.t-division--name{

        }
        .t-division--div{
            border-right: 2px solid #000;
            padding: 8px 25px 10px 10px;
            @media (max-width: 768px) {
                border-width: 1px;
                padding: 4px 10px 5px 10px;
            }
        }
        .t-division--name{
            flex: 1;
            padding: 27px 45px 30px 25px;
            @media (max-width: 768px) {
                padding: 13px 15px 15px;
            }
            i{
                flex: 1;
                text-align: left;
                &:after{
                    content: "";
                    display: block;
                    width: 1.5em;
                    height: 1.5em;
                    background-image: url(../img/arrow.svg);
                    background-size: contain;
                    background-position: center;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -0.75em;
                }
            }
            &:hover{
                i:after{
                    animation: arrow 1.2s infinite;
                }
            }
            @keyframes arrow{
                0%{
                    right: .6em;
                    opacity: 0;
                }
                50%{
                    right: .4em;
                    opacity: 1;
                }
                100%{
                    right: 0;
                    opacity: 0;
                }
            }
        }
    }
    &__copy{
        width: 55%;
        @media (max-width: 1100px) {
            width: 100%;
        }
        &--inner{
            padding: 70px 50px 90px 50px;
            background-color: var(--color-primary);
            @media (max-width: 768px) {
                padding: 30px 20px 30px 20px;
            }
        }
        .profile{
            &-title{
                @extend %font_en_b;
                font-size: unquote('min(3.7vw, 2.083rem)');
                letter-spacing: -0.05em;
                line-height: 1;
                margin-bottom: 40px;
                @media (max-width: 768px) {
                    font-size: unquote('min(9.5vw, 2.5rem)');
                    margin-bottom: 20px;
                }
            }
            &-btn{
                background-color: #000;
                @media (max-width: 768px) {
                    margin-left: -8px;
                }
                a{
                    @extend %font_ja_m;
                    font-size: unquote('min(3vw, 1.666rem)');
                    color: #fff;
                    padding: 30px 100px 30px 55px;
                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    min-height: 120px;
                    &:after{
                        content: "";
                        position: absolute;
                        background-image: url(../img/arrow_wht.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 55px;
                        height: 55px;
                        right: 40px;
                        margin: auto;
                        transform: rotate(90deg);
                        @media (max-width: 768px) {
                            right: 20px;
                            width: 28px;
                            height: 28px;
                        }
                    }
                    @media (max-width: 768px) {
                        font-size: unquote('min(5.4vw, 1.428rem)');
                        min-height: 68px;
                        padding: 15px 60px 15px 30px;
                    }
                }
            }
        }
    }
    &__copy,&__disc{
        >p,.para--text{
            font-size: calc(1rem * 0.95);
            line-height: 1.54;
            text-align: justify;
        }
    }
    &__disc{
        width: 55%;
        padding: 0 100px 0 50px;
        @media (max-width: 1100px) {
            width: 100%;
            padding: 30px 20px;
        }
        p+p{
            margin-top: 1em;
        }
        &--photo{
            margin-top: 65px;
            @media (max-width: 768px) {
                margin-top: 30px;
            }
        }
    }
    .referral{
        background-color: var(--color-primary);
        padding-top: 150px;
        @media (max-width: 768px) {
            padding-top: 20px;
        }
        .title-sec{
            padding: 0 50px;
            @media (max-width: 768px) {
                padding: 0 15px;
            }
        }
        table{
            max-width: 1168px;
        }
        caption{
            caption-side: bottom;
            text-align: left;
            padding: 20px 10px 0;
            font-size: 14px;
            @media (max-width: 768px) {
                font-size: 10px;
                padding: 20px 10px 0;
            }
        }
        &-photo{
            position: relative;
            margin-top: 90px;
            padding-top: 48%;
            min-height: 605px;
            @media (max-width: 768px) {
                margin-top: 50px;
                min-height: 420px;
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 100% 10%;
                @media (max-width: 768px) {
                    object-position: 42% 50%;
                }
            }
        }
    }
    &-consul{
        .section-headline{
            padding: 90px 50px;
            @media (max-width: 768px) {
                padding: 40px 20px 20px;
            }
        }
        .layout--inner{
            margin: 0;
        }
        .container--slider{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            margin-top: -1px;
        }
        .title-sec .en{
            br{display: none;}
            @media (max-width: 768px) {
                font-size: unquote('min(14.8vw, 3.857rem)');
                br{display: block;}
            }
        }
    }
    &-journal{
        .section-headline{
            padding: 90px 50px;
            @media (max-width: 768px) {
                padding: 40px 20px 20px;
            }
        }
        .layout--inner{
            margin: 0;
        }
        .container--slider{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            margin-top: -1px;
        }
        .title-sec .en{
            br{display: none;}
            @media (max-width: 768px) {
                font-size: unquote('min(14.8vw, 3.857rem)');
                br{display: block;}
            }
        }
    }
}