.navi{
    padding: 20px 0;
    &--logo{
        position: relative;
        width: 50px;
        margin: 30px auto 40px 25px;
        @media (min-width: 769px) {
            width: 30px;
            margin: 20px auto 90px 10px;
        }
        a{
            display: flex;
        }
    }
    &--header{
        display: flex;
        flex-direction: column;
        .navi__item{
            @extend %font_en_b;
            font-size: 28px;
            line-height: 1;
            color: #fff;
            letter-spacing: -0.04em;
            margin-top: 18px;
            position: relative;
            text-align: left;
            &:first-child{margin-top: 0;}
            @media (min-width: 769px) {
                font-size: 36px;
                margin-top: 25px;
            }
        }
        .navi__item--num{
            font-size: 26px;
            font-variant-caps: all-small-caps;
            position: relative;
            top: -5px;
            padding-right: 10px;
            @media (max-width: 768px) {
                font-size: 22px;
            }
        }
        .ja{
            @extend %font_en_m;
            font-size: 14px;
            display: block;
            margin-top: 0.5em;
            @media (max-width: 768px) {
                font-size: 10px;
            }
            .navi__item--txt &{padding-left: 0;}
        }
        .navi__item--haschild{
            display: flex;
        }
        .haschild-wrap{
            flex: 1;
            .ja{
                @media (max-width: 768px) {
                    font-size: 12px;
                }
            }
        }
        .navi__item--txt{
            display: inline-block;
            border-bottom: 2px solid #fff;
            padding-bottom: 10px;
            padding-right: 30px;
            width: 100%;
            @media (max-width: 768px) {
                border-width: 1px;
            }
        }
        a{
            display: flex;
            &:hover{
                i:after{
                    animation: arrow 1.2s infinite;
                }
            }
        }
        .icon--arrow{
            &:after{
                background: url(../img/arrow_nav.svg) no-repeat center;
                background-size: contain;
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 34px;
                height: 24px;
                margin: auto;
                @media (max-width: 768px) {
                    width: 25px;
                    height: 15px;
                }
            }
        }

        @keyframes arrow{
            0%{
                right: .4em;
                opacity: 0;
            }
            50%{
                right: .2em;
                opacity: 1;
            }
            100%{
                right: 0;
                opacity: 0;
            }
        }

    }
    &__child{
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        @media (max-width: 768px) {
            display: block;
        }
        .icon--arrow{
            width: auto;
            &:after{
                width: 17px;
                height: 12px;
                @media (max-width: 768px) {
                    width: 25px;
                    height: 15px;
                }
            }
        }
        &--item{
            width: calc((100% / 3) - (20px * 2 / 3));
            @media (max-width: 768px) {
                width: 100%;
            }
            a{
                @extend %font_ja_m;
                font-size: unquote('min(calc((2.1vw) * .95), calc((0.75rem) * .95))');
                display: flex;
                border-bottom: 2px solid #fff;
                padding: 8px 0 10px;
                position: relative;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((4.8vw) * .95), calc((1.285rem) * .95))');
                    border-width: 1px;
                    padding: 8px 0 6px;
                }
            }
        }
    }
}