.layout{
    &--inner{
        max-width: 1214px;
        margin: auto;
    }
    &__main{
        position: relative;
        padding-left: 50px;
        padding-bottom: 270px;
        @media (max-width: 768px) {
            padding-left: 40px;
        }
        &:before{
            content: "";
            position: absolute;
            width: 40px;
            height: 100%;
            background-color: #000;
            top: 0;
            left: 0;
        }
    }
}