.section{
    &-pattern{
        width: 100%;
        height: 156px;
        @media (min-width: 769px) {
            height: 340px;
        }
        &.sp{
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
    &-pattern-foot{
        width: 100%;
        height: 636px;
        margin-bottom: -331px;
        position: relative;
        z-index: -1;
        @media (min-width: 769px) {
            height: 768px;
            margin-bottom: -365px;
        }
    }
    &-img-foot{
        width: 100%;
        height: 359px;
        margin-bottom: -331px;
        position: relative;
        z-index: -1;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media (min-width: 769px) {
            height: 765px;
            margin-bottom: -365px;
        }
    }
    &-headline{
        .title-sec{
            .en{
                @extend %font_en_b;
                font-size: unquote('min(7.9vw, 4.458rem)');
                letter-spacing: -0.05em;
                line-height: 0.81;
                display: block;
                @media (max-width: 768px) {
                    font-size: unquote('min(10.2vw, 2.714rem)');
                }
            }
            .jp{
                @extend %font_ja_r;
                font-size: unquote('min(calc((1.5vw) * .95), calc((0.833rem) * .95))');
                letter-spacing: 0.02em;
                display: block;
                margin-top: 8px;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((3.2vw) * .95), calc((0.857rem) * .95))');
                }
            }
        }
    }
}