.bg{
    &-grn{background-color: var(--bg-green);}
    &-blu{background-color: var(--bg-blue);}
    &-grd-blu{
        background: rgb(0,0,230);
        background: linear-gradient(180deg, rgba(0,0,230,1) 0%, rgba(0,143,230,1) 50%, rgba(99,254,81,1) 100%);
    }
    &-it{
        background : linear-gradient(90deg, rgba(74, 102, 122, 1) 0.55%, rgba(75, 105, 125, 1) 22.55%, rgba(77, 114, 136, 1) 41.44%, rgba(82, 129, 154, 1) 59.14%, rgba(83, 134, 159, 1) 63.37%, rgba(85, 137, 159, 1) 76.19%, rgba(92, 148, 158, 1) 87.21%, rgba(103, 166, 157, 1) 97.53%, rgba(106, 171, 157, 1) 100%);
    }
    &-construction{
        background : linear-gradient(90deg, rgba(0, 180, 75, 1) 0.07%, rgba(0, 170, 146, 1) 63.18%, rgba(0, 169, 157, 1) 72.52%, rgba(37, 133, 200, 1) 99.81%);
    }
    &-maker{
        background : linear-gradient(90deg, rgba(0, 68, 200, 1) 0%, rgba(83, 69, 174, 1) 68.25%, rgba(89, 69, 172, 1) 73.43%, rgba(134, 62, 180, 1) 100%);
    }
    &-construction-vertical{
        background : linear-gradient(0deg, rgba(255, 255, 0, 1) 0%, rgba(255, 110, 0, 1) 51.65%, rgba(255, 0, 0, 1) 100%);
    }
    &-maker-vertical{
        background : linear-gradient(0deg, rgba(244, 213, 33, 1) 0.14%, rgba(166, 255, 0, 1) 50%, rgba(0, 193, 0, 1) 100%);
    }
    &-it-vertical{
        background : linear-gradient(0deg, rgba(255, 191, 0, 1) 0%, rgba(217, 73, 59, 1) 49.87%, rgba(0, 68, 255, 1) 100%);
    }
}