.button{
    &--view{
        @extend %font_en_sb;
        font-size: 1.642rem;
        letter-spacing: -0.02em;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 54px;
        @media (min-width: 769px) {
            font-size: 1.916rem;
            min-height: 114px;
        }
        &:before,&:after{
            position: absolute;
            width: 100%;
            height: 1px;
            content: "";
            background: #000;
        }
        &:before{
            top: 0;
            left: 0;
        }
        &:after{
            right: 0;
            bottom: 0;
        }
        &:hover{
            i:after{
                animation: arrow 1.2s infinite;
            }
        }
        i{
            flex: 1;
            text-align: left;
            &:after{
                content: "";
                display: block;
                width: 1.5em;
                height: 1.5em;
                background-image: url(../img/arrow.svg);
                background-size: contain;
                background-position: center;
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: -0.75em;
            }
        }
        @keyframes arrow{
            0%{
                right: .6em;
                opacity: 0;
            }
            50%{
                right: .4em;
                opacity: 1;
            }
            100%{
                right: 0;
                opacity: 0;
            }
        }
    }
    &--frame{
        @extend %font_en_sb;
        font-size: 1.642rem;
        letter-spacing: -0.02em;
        transition: all .6s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 110px;
        .page-top-media__item--btn &{
            padding: 18px;
            min-height: 52px;
            @media (min-width: 769px) {
                padding: 27px 50px;
            }
        }
        @media (min-width: 769px) {
            font-size: 1.916rem;
            padding: 27px 50px;
        }
        &:hover{
            // color: #fff;
            // background-color: #000;
            // .svg--dark{
            //     fill: #fff;
            // }
        }
    }
    &--plane{
        font-family: acumin-pro, noto-sans-cjk-jp, sans-serif;
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.02em;
        width: 100%;
        border: 1px solid #000;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;
        padding: 14px 24px;
        transition: all .3s;
        &:hover{
            background-color: #000;
            color: #fff;
        }
        @media (min-width: 769px) {
            font-size: 40px;
            min-height: 100px;
        }
    }
    &--entry{
        @extend %font_en_sb;
        font-size: 2.857rem;
        background-color: var(--bg-green);
        position: relative;
        display: flex;
        flex-direction: column;
        line-height: 1;
        padding: 10px 20px 15px 20px;
        @media (min-width: 769px) {
            padding: 45px 50px 55px 50px;
            font-size: 5.333rem;
        }
        .text--sm{
            @extend %font_ja_m;
            font-size: calc(clamp(16px,3vw,1.666rem) * 0.95);
            display: block;
            margin-top: 0.25em;
        }
    }
    &--contact{
        @extend %font_en_sb;
        font-size: unquote('min(calc((3.7vw) * .95), calc((1.666rem) * .95))');
        background-color: #000;
        color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        line-height: 1;
        padding: 49px 50px 50px 50px;
        @media (max-width: 768px) {
            font-size: unquote('min(calc((5.4vw) * .95), calc((1.428rem) * .95))');
            padding: 25px 20px 24px 20px;
        }
    }
    &--open{
        @extend %font_en_sb;
        font-size: 28px;
        border: 1px solid #000;
        border-radius: 3em;
        background-color: #fff;
        width: 100%;
        padding: 23px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        &:before{
            content: "";
            display: block;
            width: 30px;
            height: 12px;
            transform: scale(1, -1);
            background-image: url(../img/arrow_aco.svg);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            @media (max-width: 768px) {
                width: 15px;
                height: 6px;
            }
        }
        @media (max-width: 768px) {
            font-size: 14px;
            padding: 12px 10px;
        }
    }
}