.page-journal{
    &__location{
        @extend %font_en_b;
        font-size: unquote('min(18.2vw, 230px)');
        letter-spacing: -0.03em;
        line-height: 1;
        margin-top: -92px;
        margin-left: -10px;
        z-index: -1;
        @include js-fadein;
        &:before{
            background-color: var(--color-primary);
        }
        @media (max-width: 1000px) {
            margin-top: -72px;
        }
        @media (max-width: 768px) {
            margin-top: -56px;
        }
        @media (max-width: 450px) {
            margin-top: -28px;
            margin-left: -5px;   
        }
    }
    &__pager{
        padding: 60px 20px 0;
        text-align: center;
        @media (min-width: 769px) {
            padding: 100px 50px 0;
        }
    }
}

.page-journal-article{
    &--wrapper{
        border-bottom: 1px solid #000;
        overflow: hidden;
        @media (min-width: 769px) {
            padding: 0 50px;
        }
        @media (min-width: 1001px) {
            padding: 0 100px;
        }
    }
}

.page-journal-other{
    &__heading{
        @include js-fadein;
        &:before{
            background-color: var(--color-primary);
        }
        .en{
            font-size: unquote('min(10.4vw, 107px)');
        }
    }
    &__slider{
        .container--slider{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            margin-top: -1px;
        }
    }
}