.loading-screen{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;left: 0;
    z-index: 100;
    .page-leady &,
    // .wf-active &,
    .page-back &{pointer-events: none;}
    &__green,
    &__blue{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__green{
        background-color: var(--bg-green);
        animation: loading .4s ease-out both;
        .page-jump &{
            clip-path: inset(0 100% 0 0);
            animation: jumping .4s ease-in .4s both;
        }
    }
    &__blue{
        background-color: var(--bg-blue);
        transition: clip-path .4s ease-in .2s;
        clip-path: inset(0 0 0 0);
        .page-leady &,
        // .wf-active &,
        .page-back &{
            clip-path: inset(0 0 0 100%);
        }
        .page-jump &{
            clip-path: inset(0 100% 0 0);
            animation: jumping .4s ease-out both;
        }
    }
}
@keyframes loading{
    0%{clip-path: inset(0 0 0 0);}
    100%{clip-path: inset(0 0 0 100%);}
}
@keyframes jumping{
    0%{clip-path: inset(0 100% 0 0);}
    100%{clip-path: inset(0 0 0 0);}
}

.loading-screen-in{
    display: block;
    position: fixed;
    z-index: 1000;
    height: 100%;
    width: auto;
    max-width: none;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 1s;
    pointer-events: none;
    .page-leady &,
    // .wf-active &,
    .page-back &{
        transform: translateX(50%);
    }
}
.loading-screen-out{
    position: fixed;
    z-index: 1000;
    height: 100%;
    width: auto;
    max-width: none;
    top: 0;
    left: 50%;
    transform: translateX(-150%);
    transition: transform 1s;
    pointer-events: none;
    .page-jump &{
        transform: translateX(-50%);
    }
}