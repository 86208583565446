.js-inview{
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s,transform 1s;
    &.inviewed{
        opacity: 1;
        transform: translateY(0);
    }
}
.js-fadein{
    opacity: 0;
    transition: opacity 1s,transform 1s;
    &.inviewed{
        opacity: 1;
    }
}
.js-mask{
    
}


/* テキスト左から */
.js-left{
    opacity: 0;
    overflow: hidden;
    display: inline-block;
    &.inviewed{
        animation-name: slideTextX100;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        opacity: 0;
    }
}
.js-left-inner{
    display: inline-block;
    &.inviewed{
        animation-name: slideTextX-100;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        opacity: 0;
    }
}
@keyframes slideTextX100{
    from{
        transform: translateX(-100%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slideTextX-100{
    from{
        transform: translateX(100%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
}
/* テキスト左から */


@mixin js-fadein{
    overflow: hidden;
    position: relative;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        transition: 1s;
    }
    &.inviewed{
        opacity: 1;
        &:before{
            transform: translateX(100%);
        }
    }
}