@mixin placeholderColor($color){
    &:placeholder-shown{
        color: $color;
    }
    &::-webkit-input-placeholder{
        color: $color;
    }
}

.page-contact{
    padding: 0 25px;
    letter-spacing: 0.02em;
    @media (min-width: 769px) {
        padding: 0 50px;
    }
    &__form{
        .title--line{
            margin-top: 40px;
            margin-bottom: 30px;
            @media (min-width: 769px) {
                margin-top: 150px;
                margin-bottom: 95px;
            }
            &:first-child{
                margin-top: 0;
            }
        }
        &--inner{
            padding: 0;
            @media (min-width: 769px) {
                padding: 0 50px;
            }
        }
        .form__lane{
            @media (min-width: 769px) {
                border-top: 1px solid #000;
                padding-top: 45px;
            }
            .title--line{
                @media (max-width: 768px) {
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                    padding: 1em 0;
                    margin-bottom: 30px;
                }
            }
            .radio__list--item{width: auto;}
        }
        .form__section{
            margin-top: 40px;
            @media (min-width: 769px) {
                margin-top: 45px;
            }
        }
        .form__item,.form__list{
            margin-top: 20px;
            &:first-child{margin-top: 0;}
            @media (min-width: 769px) {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 60px;
            }
            &.required{
                .form__heading{
                    &:after{
                        content: "*";
                        font-variant-caps: all-small-caps;
                        display: flex;
                        margin-top: -5px;
                    }
                }
            }
            input{
                &[type="text"], &[type="tel"], &[type="email"]{
                    display: flex;
                    width: 100%;
                    height: 34px;
                    line-height: 1;
                    border: 1px solid #8c8c8c;
                    border-radius: 0;
                    @media (min-width: 769px) {
                        border: 1px solid #000;
                        height: 68px;
                    }
                }
            }
            input, select{
                padding: 0 0.5em;
                &:focus{
                    outline: none;
                }
            }
            select{
                display: flex;
                width: 100%;
                height: 34px;
                line-height: 1;
                border: 1px solid #8c8c8c;
                background-color: #fff;
                border-radius: 0;
                cursor: pointer;
                @media (min-width: 769px) {
                    border: 1px solid #000;
                    height: 68px;
                }
            }
            textarea{
                width: 100%;
                min-height: 150px;
                resize: none;
                line-height: 1.75;
                padding: 0.5em;
                border: 1px solid #000;
                border-radius: 0;
                line-height: 1.33;
                @media (min-width: 769px) {
                    min-height: 352px;
                }
            }
            input, textarea{
                font-family: inherit;
                font-size: 100%;
                font-weight: inherit;
                font-style: inherit;
                @include placeholderColor(#b3b3b3);
            }
        }
        .form__list{
            .form__heading{
                min-height: auto;
            }
            @media (max-width: 768px) {
                margin-bottom: 30px;
            }
        }
        .form__heading{
            @extend %font_ja_m;
            font-size: calc(1.083rem * 0.95);
            padding-right: 1em;
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 1em;
            br{display: none;}
            @media (min-width: 769px) {
                min-height: 68px;
                width: 310/907*100+%;
                margin-bottom: 0;
                br{display: block;}
            }
            .form__lane &{
                @extend %font_ja_b;
                font-size: calc(2.142rem * 0.95);
                @media (min-width: 769px) {
                    font-size: calc(1.416rem * 0.95);
                }
            }
        }
        .form__radio{
            flex: 1;
            input{
                display: none;
            }
            label{
                span{
                    display: flex;
                    height: 100%;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    position: relative;
                    &:before, &:after{
                        content: "";
                        border-radius: 50%;
                    }
                    &:before{
                        width: 25px;
                        height: 25px;
                        background-color: #fff;
                        border: 1px solid #000;
                        margin-right: 0.5em;
                        @media (min-width: 769px) {
                            width: 36px;
                            height: 36px;
                        }
                    }
                    &:after{
                        width: 12px;
                        height: 12px;
                        background-color: #000;
                        position: absolute;
                        top: 50%;
                        left: 6px;
                        margin-top: -6px;
                        opacity: 0;
                        @media (min-width: 769px) {
                            width: 18px;
                            height: 18px;
                            left: 9px;
                            margin-top: -9px;
                        }
                    }
                }
            }
            .radio__list{
                display: flex;
                flex-wrap: wrap;
                margin-left: -2em;
                margin-top: -1.5em;
                &--item{
                    @extend %font_ja_m;
                    display: inline-block;
                    margin-left: 2em;
                    margin-top: 1.5em;
                }
            }
            input[type=radio]:checked + span{
                &:after{
                    opacity: 1;
                }
            }
        }
        .form__input,.form__select{
            flex: 1;
            font-size: 12px;
            letter-spacing: 0.02em;
            color: #000;
            @media (min-width: 769px) {
                font-size: 24px;
            }
        }
        .form__select{
            position: relative;
            &:after{
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                right: 20px;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                border-bottom: 2px solid #000;
                border-right: 2px solid #000;
                z-index: 0;
                @media (max-width: 768px) {
                    width: 8px;
                    height: 8px;
                    right: 10px;
                    top: 45%;
                    border-width: 1px;
                }
            }
        }
        .form__agreement{
            font-size: 1.083rem;
            text-align: center;
            &--wrapper{
                position: relative;
                display: flex;
                @media (min-width: 451px) {
                    justify-content: center;
                }
                &:after{
                    content: "*";
                    font-variant-caps: all-small-caps;
                    display: flex;
                    margin-top: -5px;
                }
            }
            a{
                text-decoration: underline;
            }
            input{
                display: none;
            }
            .form-check-input{
                cursor: pointer;
                display: inline-block;
                padding-left: 2.5em;
                position: relative;
                &:before,&:after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &:before{
                    background-color: #fff;
                    border: 1px solid #000;
                    width: 26px;
                    height: 26px;
                    @media (min-width: 769px) {
                        width: 40px;
                        height: 40px;
                    }
                }
                &:after{
                    border-right: 2px solid #000;
                    border-bottom: 2px solid #000;
                    content: "";
                    width: 8px;
                    height: 12px;
                    top: 5px;
                    left: 8px;
                    opacity: 0;
                    transform: rotate(45deg);
                    @media (min-width: 769px) {
                        width: 10px;
                        height: 18px;
                        top: 7px;
                        left: 15px;
                    }
                }
            }
            input[type=checkbox]:checked + span{
                &:after{
                    opacity: 1;
                }
            }
        }
        .form__btn{
            text-align: center;
            margin: 50px auto 0;
            .btn{
                max-width: 350px;
                width: 100%;
                margin: auto;
                position: relative;
                &__arrow{
                    display: block;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    right: 2em;
                    top: 50%;
                    margin-top: -7px;
                }
            }
            input[type=submit]{
                cursor: pointer;
                background-color: #fff;
                border: 1px solid #000;
                color: #000;
                width: 100%;
                height: 72px;
                transition: background-color 150ms ease,border 150ms ease;
                &:hover{
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }
    &__btn{
        margin: 70px auto 0;
        @media (min-width: 769px) {
            margin: 140px auto 0;
        }
    }
}

/* MW WP Form */
.mw_wp_form .form__radio .horizontal-item{
    display: inline-block;
    margin-left: 2em;
    margin-top: 1.5em;
    .form__lane &{
        width: auto;
    }
}
.mw_wp_form .form__radio .horizontal-item + .horizontal-item{
    margin-left: 2em;
}
.mwform-checkbox-field-text{
    cursor: pointer;
    display: inline-block;
    padding-left: 2.5em;
    position: relative;
    &:before,&:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
    }
    &:before{
        background-color: #fff;
        border: 1px solid #000;
        width: 26px;
        height: 26px;
        @media (min-width: 769px) {
            width: 40px;
            height: 40px;
        }
    }
    &:after{
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        content: "";
        width: 8px;
        height: 12px;
        top: 5px;
        left: 8px;
        opacity: 0;
        transform: rotate(45deg);
        @media (min-width: 769px) {
            width: 10px;
            height: 18px;
            top: 7px;
            left: 15px;
        }
    }
}
.mw_wp_form_confirm{
    .form__lane .form__item{
        align-items: center;
    }
    .form__heading{
        align-items: flex-start;
    }
    .form__list{
        align-items: baseline;
        @media (max-width: 768px) {
            .form__radio{
                margin-top: 2em;
            }
        }
    }
    .form__radio .radio__list{
        margin-left: 0;
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
    .form__select{
        &:after{
            content: none;
        }
    }
}
.mw_wp_form_preview{
    .form__agreement{
        display: none;
    }
}
