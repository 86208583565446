.article{
    &--journal{
        position: relative;
        padding: 0 0 100px;
        max-width: 1116px;
        margin: auto;
        @media (max-width: 768px) {
            padding-bottom: 2em;
        }
        &:before,&:after{
            position: absolute;
            top: 0;
            content: "";
            background-color: #000;
            width: 1px;
            height: 100%;
            display: block;
            @media (max-width: 768px) {
                content: none;
            }
        }
        &:before{
            left: 0;
        }
        &:after{
            right: 0;
        }
        h1,h2,h3{
            padding: 0 90px;
        }
        h1,h2{
            @extend %font_ja_m;
            font-size: calc(2.083rem * 0.95);
            position: relative;
            padding: 1em 90px;
            @media (max-width: 768px) {
                font-size: calc(1.285rem * 0.95);
                padding: 1em 20px;
            }
            &:before,&:after{
                position: absolute;
                content: "";
                display: block;
                background-color: #000;
                margin-left: calc(-1 * (100vw - 100% - 50px) / 2);
                margin-right: calc(-1 * (100vw - 100% - 50px) / 2);
                width: calc(100vw - 50px);
                height: 1px;
                left: 0;
                @media (max-width: 768px) {
                    margin-left: calc(-1 * (100vw - 100% - 40px) / 2);
                    margin-right: calc(-1 * (100vw - 100% - 40px) / 2);
                    width: calc(100vw - 40px);
                }
            }
            &:before{
                top: 0;
            }
            &:after{
                bottom: 0;
            }
            &:first-child{
                margin-top: -1px;
            }
            +figure{
                @media (max-width: 768px) {
                    margin-top: 0;
                }
            }
        }
        h3,h4{
            @extend %font_ja_b;
            padding: 0 90px;
            @media (max-width: 768px) {
                padding: 0 20px;
            }
            +p{
                margin-top: 0.5em;
            }
        }
        h3{
            font-size: calc(1.166rem * 0.95);
            @media (max-width: 450px) {
                font-size: calc(1.142rem * 0.95)
            }
        }
        h4{
            font-size: calc(1rem * 0.95);
        }
        p, ul, ol{
            @extend %font_ja_r;
            font-size: calc(0.916rem * 0.95);
            padding: 0 90px;
            margin: 1.5em 0;
            @include margin0;
            @media (max-width: 768px) {
                padding: 0 20px;
            }
            @media (max-width: 450px) {
                font-size: calc(1rem * 0.95);
            }
        }
        p{
            text-align: justify;
            +h1,+h2{
                margin-top: 2em;
                @media (max-width: 768px) {
                    margin-top: 1.25em;
                }
            }
        }
        a{
            text-decoration: underline;
        }
        figure{
            text-align: center;
            display: block;
            margin: 3em auto;
            padding: 0 90px;
            @include margin0;
            @media (max-width: 768px) {
                padding: 0;
                margin: 1em auto;
            }
            img{
                max-width: 770px;
                margin: auto;
                width: 100%;
            }
        }
    }
    &--recruit{
        h3{
            @extend %font_en_b;
            font-size: calc(clamp(18px,7vw,2.916rem) * 0.95);
            letter-spacing: -0.03em;
            line-height: 1.2;
            margin: 50px 0 0;
            @media (max-width: 768px) {
                margin: 25px 0 0;
            }
            +p{
                margin: 0 0 0.5em;
            }
        }
        p, ul, ol{
            @extend %font_ja_m;
            font-size: calc(clamp(13px,4vw,1.166rem) * 0.95);
        }
        p{
            text-align: justify;
            margin: 1em 0;
            @include margin0;
            &:last-child{
                border-bottom: 1px solid #000;
                padding-bottom: 1em;
            }
            strong{
                font-size: calc(clamp(14px,3vw,1.333rem) * 0.95);
            }
        }
        ul{
            li{
                padding: 0.5em 0;
                border-top: 1px solid #000;
                &:last-child{
                    border-bottom: 1px solid #000;
                }
                @include margin0;
            }
        }
        strong, b{
            font-weight: 700;
        }
        small{
            @extend %font_ja_r;
            font-size: calc(clamp(11px,5vw,0.916rem) * 0.95);
            line-height: 1.27;
            letter-spacing: -0.03em;
            display: inline-block;
        }
    }
}