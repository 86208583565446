.flip-screen{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .wf-active &{
        pointer-events: none;
    }
    &__color{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--theme-color);
        clip-path: inset(0 0 0 0%);
        .wf-active &{
            // clip-path: inset(0 100% 0 0);
            // transition: clip-path .8s ease-in .4s;
            animation: flip .4s ease-out both;
            animation-delay: 1s;
        }
    }
}
@keyframes flip{
    0%{clip-path: inset(0 0 0 0);}
    // 50%{clip-path: inset(0 0 0 0);}
    100%{clip-path: inset(0 100% 0 0);}
}