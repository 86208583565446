.list{
    &--bullets{
        li{
            text-indent: -1em;
            padding-left: 1em;
            &:before{
                content: "・";
            }
        }
    }
}