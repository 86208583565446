.container{
    &--tags{
        .section-tag{
            &__container{
                padding: 20px;
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                @media (min-width: 769px) {
                    padding: 35px 50px;
                }
            }
            &__heading{
                padding: 20px;
                @media (min-width: 769px) {
                    padding: 35px 50px;
                }
                .title--tag{
                    max-width: 1214px;
                    margin: auto;
                }
            }
        } 
    }
    &--card{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        position: relative;
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        &.col3{
            grid-template-columns: repeat(3, 1fr);
            gap: 0 40px;
            @media (max-width: 768px) {
                gap: 0;
            }
        }
    }
    &--bar{
        display: flex;
    }
    &--slider{
        position: relative;
        margin: 0 auto 0 0;
        .swiper{
            margin-left: 0;
            padding-left: 20px;
            @media (min-width: 769px) {
                padding-left: 40px;
            }
            &.not-slide{
                padding-right: 20px;
                @media (min-width: 769px) {padding-right: 40px;}
            }
            &:not(.not-slide){
                .swiper-slide:last-child{
                    margin-left: -20px;
                    padding-right: 20px;
                    width: 200px;
                    background-color: var(--color-primary);
                    @media (min-width: 769px) {
                        margin-left: -40px;
                        padding-right: 40px;
                        width: 360px;
                    }
                    position: relative;
                    &::after{
                        content: "";
                        display: block;
                        width: 200px;
                        height: 100%;
                        background-color: var(--color-primary);
                        position: absolute;
                        top: 0;
                        right: -200px;
                    }
                }
            }
        }
        .swiper-wrapper{
            overflow: visible;
        }
        .swiper-slide{
            position: relative;
            width: auto;
            >.card__item{
                width: 157px;
                border-right: 1px solid #000;
                border-left: 1px solid #000;
                margin-left: 0;
                border-top: none;
                background-color: transparent;
                @media (min-width: 769px) {
                    width: 314px;
                }
                .card__item--thumb{
                    margin-top: 1px;
                    border-bottom: none;
                }
                .text--date{
                    border-bottom: none;
                    min-height: 24px;
                    @media (min-width: 769px) {
                        min-height: 45px;
                    }
                }
                .btn{
                    border-top: none;
                    display: flex;
                    align-items: center;
                    min-height: 30px;
                    @media (min-width: 769px) {
                        min-height: 56px;
                    }
                }
                .button--frame{
                    min-height: auto;
                    @media (min-width: 769px) {
                        font-size: 1.25rem;
                    }
                }
            }
        }
        .hr{
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #000;
            left: 0;
            z-index: -1;
            &:nth-child(1){top: 0;}
            &:nth-child(2){
                top: 158px;
                @media (min-width: 769px) {
                    top: 315px;
                }
            }
            &:nth-child(3){
                top: 181px;
                @media (min-width: 769px) {
                    top: 359px;
                }
            }
            &:nth-child(4){
                top: 225px;
                @media (min-width: 769px) {
                    top: 439px;
                }
            }
            &:nth-child(5){bottom: 0;}
        }
    }
}