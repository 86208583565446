.footer{
    position: relative;
    padding: 0 40px;
    margin-left: 50px;
    z-index: 1;
    @media (max-width: 768px) {
        padding: 0 20px;
        margin-left: 40px;
    }
    &.overlap{
        color: #ffff;
    }
    &__inner{
        @extend %font_ja_b;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #000;
        padding: 33px 0;
        @media (max-width: 768px) {
            padding: 22px 0;
        }
        .overlap &{
            border-color: #fff;
        }
    }
    &__navi{
        font-size: 18px;
        @media (max-width: 768px) {
            font-size: 11px;
        }
    }
    &__copyright{
        font-size: 20px;
        @media (max-width: 768px) {
            font-size: 11px;
        }
    }
}