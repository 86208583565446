.loop-wrapper{
    padding: 20px;
    overflow: hidden;
    &.upper{
        @media (max-width: 768px) {
            padding: 5px;
        }
    }
    &.lower{
        padding: 0 20px;
        @media (max-width: 768px) {
            padding: 0 5px 5px;
        }
    }
    .row1 &{
        padding: 0 15px 15px;
        @media (max-width: 768px) {
            padding: 0px 15px 5px;
        }
    }
}
.loop-inner{
    white-space: nowrap;
}
.loop-item{
    line-height: 1;
    font-size: 60px;
    font-family: acumin-pro,sans-serif;
    font-weight: 700;
    font-style: normal;
    display: inline-block;
    padding-right: 20px;
    &.en{
        font-size: 120px;
        letter-spacing: -0.05em;
        line-height: 1;
        padding-right: 100px;
        @media (max-width: 768px) {
            font-size: 52px;
            padding-right: 30px;
        }
        .page-consultant-second--wapper &{
            font-size: 105px;
            margin-top: -10px;
            @media (max-width: 768px) {
                font-size: 52px;
            }
        }
    }
    .num{
        position: relative;
        font-size: 2.916rem;
        padding-right: 25px;
        top: -36px;
        @media (max-width: 768px) {
            font-size: 2.5rem;
            padding-right: 10px;
            top: -18px;
        }
    }
    &.jp{
        font-size: 24px;
        padding-right: 40px;
        @media (max-width: 768px) {
            font-size: 12px;
            padding-right: 20px;
        }
    }
}
.loop-v-wrapper{
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 0 20px;
    @media (max-width: 768px) {
        padding: 0 10px;
    }
}
.loop-v-item{
    padding-bottom: 20px;
    @media (max-width: 768px) {
        // padding-bottom: 10px;
    }
}
.loop-v-inner{
    white-space: nowrap;
}