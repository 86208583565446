$box_hight_pc: 484px;
$hight1pc: $box_hight_pc * 1;
$hight2pc: $box_hight_pc * 2;
$hight3pc: $box_hight_pc * 3;
$hight4pc: $box_hight_pc * 4;
$hight5pc: $box_hight_pc * 5;
$hight6pc: $box_hight_pc * 6;
$hight7pc: $box_hight_pc * 7;
$hight8pc: $box_hight_pc * 8;
$hight9pc: $box_hight_pc * 9;
$hight10pc: $box_hight_pc * 10;
$hight11pc: $box_hight_pc * 11;
$hight12pc: $box_hight_pc * 12;
$hight13pc: $box_hight_pc * 13;

$box_hight_sp: 298px;
$hight1sp: $box_hight_sp * 1;
$hight2sp: $box_hight_sp * 2;
$hight3sp: $box_hight_sp * 3;
$hight4sp: $box_hight_sp * 4;
$hight5sp: $box_hight_sp * 5;
$hight6sp: $box_hight_sp * 6;
$hight7sp: $box_hight_sp * 7;
$hight8sp: $box_hight_sp * 8;
$hight9sp: $box_hight_sp * 9;
$hight10sp: $box_hight_sp * 10;
$hight11sp: $box_hight_sp * 11;
$hight12sp: $box_hight_sp * 12;
$hight13sp: $box_hight_sp * 13;

.card{
    &__item{
        border: 1px solid #000;
        background-color: var(--color-primary);
        margin-left: -1px;
        border-top: 10px solid #000;
        &:nth-child(-n+2){
            border-top: 1px solid #000;
        }
        @media (min-width: 769px) {
            border-top: 28px solid #000;
            &:nth-child(-n+4){
                border-top: 1px solid #000;
            }
        }
        &--thumb{
            line-height: 1;
            position: relative;
            overflow: hidden;
            padding-top: 100%;
            border-bottom: 1px solid #000;
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .6s;
            }
        }
        .text--date{
            font-size: clamp(14px, 2.5vw, 1.083rem);
            border-bottom: 1px solid #000;
            display: flex;
            align-items: center;
            padding: 0 0.5em;
            min-height: 30px;
            @media (min-width: 769px) {
                min-height: 45px;
            }
            .page-journal-other__slider &{
                border-bottom: none;
            }
        }
        .text--desc{
            @extend %font_ja_r;
            font-size: calc( 0.833rem * 0.95);
            min-height: 80px;
        }
        .btn{
            border-top: 1px solid #000;
            background-color: #fff;
            padding: 0 1em;
        }
        .icon--target{
            width: 18px;
            @media (min-width: 769px) {
                width: 33px;
            }
        }
        .icon--arrow{
            width: 24px;
            @media (min-width: 769px) {
                width: 48px;
            }
        }
        .button--frame{
            font-size: 1.071rem;
            padding: 0;
            min-height: 38px;
            position: relative;
            @media (min-width: 769px) {
                font-size: 1.25rem;
                min-height: 70px;
            }
        }
        a{
            .icon--arrow{
                position: absolute;
                right: 0;
                @keyframes arrow{
                    0%{
                        opacity: 0;
                    }
                    50%{
                        right: .4em;
                        opacity: 1;
                    }
                    100%{
                        right: 0;
                        opacity: 0;
                    }
                }
            }
            &:hover{
                .btn{
                    // color: #fff;
                    // background-color: #000;
                }
                .svg--dark{
                    // fill: #fff;
                }
                .icon--arrow{
                    animation: arrow 1.2s infinite;
                }
                img{
                    transform: scale(1.12);
                }
                .button--frame{pointer-events: none;}
            }
        }
        .page-journal-other__slider &{
            border-top: none;
        }
    }
    &__frame{
        // width: 157px;
        // border-right: 1px solid #000;
        // border-left: 1px solid #000;
        // @media (min-width: 769px) {
        //     width: 314px;
        // }
        // &--thumb{
        //     line-height: 1;
        //     position: relative;
        //     overflow: hidden;
        //     padding-top: 100%;
        //     margin-top: 1px;
        //     img{
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //         transition: all .6s;
        //     }
        // }
        &--title{
            display: flex;
            align-items: center;
            min-height: 44px;
            padding: 0 0.5em;
            @media (min-width: 769px) {
                min-height: 80px;
            }
            .desc{
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                @extend %font_ja_r;
                font-size: calc(0.833rem * 0.95);
                text-align: justify;
            }
        }
        // .text--date{
        //     font-size: clamp(14px, 2.5vw, 1.083rem);
        //     display: flex;
        //     align-items: center;
        //     padding: 0 0.5em;
        //     min-height: 24px;
        //     @media (min-width: 769px) {
        //         min-height: 45px;
        //     }
        // }
        // .btn{
        //     display: flex;
        //     align-items: center;
        //     background-color: #fff;
        //     padding: 0 1em;
        //     min-height: 30px;
        //     margin-bottom: 1px;
        //     @media (min-width: 769px) {
        //         min-height: 56px;
        //     }
        // }
        // .icon--arrow{
        //     width: 24px;
        //     @media (min-width: 769px) {
        //         width: 48px;
        //     }
        // }
        // .button--frame{
        //     font-size: 1.071rem;
        //     padding: 0;
        //     transition: all .6s;
        //     min-height: auto;
        //     @media (min-width: 769px) {
        //         font-size: 1.25rem;
        //     }
        // }
        // a{
        //     &:hover{
        //         .btn{
        //             color: #fff;
        //             background-color: #000;
        //         }
        //         .svg--dark{
        //             fill: #fff;
        //         }
        //         img{
        //             transform: scale(1.12);
        //         }
        //     }
        // }
    }
    &__more{
        display: flex;
        justify-content: center;
        width: 117px;
        margin: 20px auto;
        @media (min-width: 769px) {
            margin-top: 40px;
            width: 234px;
        }
        a{
            transition: all 0.6s;
            &:hover{
                .fill-wht{
                    background-color: #000;
                    fill: #fff;
                }
            }
        }
    }
    &__mbr--wrapper{
        position: relative;
        overflow: hidden;
        @media (min-width: 769px) {
            padding: 0 50px;
        }
        .hr{
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #000;
            z-index: 1;
            &:nth-child(1){top: 0;}
            &:nth-child(2){
                top: 314px;
                @media (max-width: 450px) {top: 196px;}
            }
            &:nth-child(3){
                top: 425px;
                @media (max-width: 450px) {top: 257px;}
            }
            &:nth-child(4){
                bottom: 0;
            }
            &:nth-child(5){
                top: $hight1pc - 1px;
                @media (max-width: 450px) {top: $hight1sp;}
            }
            &:nth-child(6){
                top: $hight1pc + 314px;
                @media (max-width: 450px) {top: $hight1sp + 196px;}
            }
            &:nth-child(7){
                top: $hight1pc + 425px;
                @media (max-width: 450px) {top: $hight1sp + 258px;}
            }
            &:nth-child(8){
                bottom: 0;
                @media (max-width: 450px) {top: $hight2sp;}
            }

            &:nth-child(9){
                top: $hight2pc - 1px;
                @media (max-width: 450px) {top: $hight2sp + 196px;}
            }
            &:nth-child(10){
                top: $hight2pc + 314px;
                @media (max-width: 450px) {top: $hight2sp + 258px;}
            }
            &:nth-child(11){
                top: $hight2pc + 425px;
                @media (max-width: 450px) {
                    top: auto;
                    bottom: 0;
                }
            }
            &:nth-child(12){
                bottom: 0;
                @media (max-width: 450px) {top: $hight3sp;}
            }
            &:nth-child(13){
                top: $hight3pc - 1px;
                @media (max-width: 450px) {top: $hight3sp + 196px;}
            }
            &:nth-child(14){
                top: $hight3pc + 314px;
                @media (max-width: 450px) {top: $hight3sp + 258px;}
            }
            &:nth-child(15){
                top: $hight3pc + 425px;
                @media (max-width: 450px) {
                    top: auto;
                    bottom: 0;
                }
            }
            &:nth-child(16){
                bottom: 0;
                @media (max-width: 450px) {top: $hight4sp;}
            }
            &:nth-child(17){
                top: $hight4pc - 1px;
                @media (max-width: 450px) {top: $hight4sp + 196px;}
            }
            &:nth-child(18){
                top: $hight4pc + 314px;
                @media (max-width: 450px) {top: $hight4sp + 258px;}
            }
            &:nth-child(19){
                top: $hight4pc + 425px;
                @media (max-width: 450px) {
                    top: auto;
                    bottom: 0;
                }
            }
            &:nth-child(20){
                bottom: 0;
                @media (max-width: 450px) {top: $hight5sp;}
            }
            &:nth-child(21){
                top: $hight5pc - 1px;
                @media (max-width: 450px) {top: $hight5sp + 196px;}
            }
            &:nth-child(22){
                top: $hight5pc + 314px;
                @media (max-width: 450px) {top: $hight5sp + 258px;}
            }
            &:nth-child(23){
                top: $hight5pc + 425px;
                @media (max-width: 450px) {
                    top: auto;
                    bottom: 0;
                }
            }
            &:nth-child(24){
                bottom: 0;
                @media screen and (min-width:451px) and ( max-width:768px) {
                    top: $hight6pc;
                }
                @media (max-width: 450px) {top: $hight6sp;}
            }
            // &:nth-child(n + 9) {
            //     @media (min-width: 769px) {
            //         display: none;
            //     }
            // }
            &:nth-child(25){
                top: $hight6sp + 196px;
                @media (min-width: 451px) {
                    top: $hight6pc + 314px;
                }
            }
            &:nth-child(26){
                top: $hight6sp + 258px;
                @media (min-width: 451px) {
                    top: $hight6pc + 425px;
                }
            }
            &:nth-child(27){
                bottom: 0;
            }
            &:nth-child(28){
                top: $hight7sp;
                @media (min-width: 451px) {
                    top: $hight7pc;
                }
            }
            &:nth-child(29){
                top: $hight7sp + 196px;
                @media (min-width: 451px) {
                    top: $hight7pc + 314px;
                }
            }
            &:nth-child(30){
                top: $hight7sp + 258px;
                @media (min-width: 451px) {
                    top: $hight7pc + 425px;
                }
            }
            &:nth-child(31){
                bottom: 0;
            }
            &:nth-child(32){
                top: $hight8sp;
                @media (min-width: 451px) {
                    top: $hight8pc;
                }
            }
            &:nth-child(33){
                top: $hight8sp + 196px;
                @media (min-width: 451px) {
                    top: $hight8pc + 314px;
                }
            }
            &:nth-child(34){
                top: $hight8sp + 258px;
                @media (min-width: 451px) {
                    top: $hight8pc + 425px;
                }
            }
            &:nth-child(35){
                bottom: 0;
            }
            &:nth-child(36){
                top: $hight9sp;
                @media (min-width: 451px) {
                    top: $hight9pc;
                }
            }
            &:nth-child(37){
                top: $hight9sp + 196px;
                @media (min-width: 451px) {
                    top: $hight9pc + 314px;
                }
            }
            &:nth-child(38){
                top: $hight9sp + 258px;
                @media (min-width: 451px) {
                    top: $hight9pc + 425px;
                }
            }
            &:nth-child(39){
                bottom: 0;
            }
            &:nth-child(40){
                top: $hight10sp;
                @media (min-width: 451px) {
                    top: $hight10pc;
                }
            }
            &:nth-child(41){
                top: $hight10sp + 196px;
                @media (min-width: 451px) {
                    top: $hight10pc + 314px;
                }
            }
            &:nth-child(42){
                top: $hight10sp + 258px;
                @media (min-width: 451px) {
                    top: $hight10pc + 425px;
                }
            }
            &:nth-child(43){
                bottom: 0;
            }
            &:nth-child(44){
                top: $hight11sp;
                @media (min-width: 451px) {
                    top: $hight11pc;
                }
            }
            &:nth-child(45){
                top: $hight11sp + 196px;
                @media (min-width: 451px) {
                    top: $hight11pc + 314px;
                }
            }
            &:nth-child(46){
                top: $hight11sp + 258px;
                @media (min-width: 451px) {
                    top: $hight11pc + 425px;
                }
            }
            &:nth-child(47){
                bottom: 0;
            }
        }
        .container--card{
            // max-width: 1214px;
            margin: auto;
        }
    }
    &__mbr{
        margin-left: -1px;
        // margin-top: 1px;
        border-right: 1px solid #000;
        border-left: 1px solid #000;
        // max-width: 304px;
        backface-visibility: hidden;
        overflow: hidden;
        .consultant-slider &{
            width: 314px;
        }
        &--thumb{
            position: relative;
            overflow: hidden;
            min-height: 196px;
            transition: all .4s;
            // background-color: var(--theme-color);
            @media (min-width: 451px) {
                min-height: 314px;
            }
            img{
                clip-path: inset(0 100% 0 0);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
                // transition: clip-path .4s;
                .inviewed &{
                    clip-path: inset(-1px -1px 0 0);
                }
            }
        }
        .text--name{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 0 0.5em;
            min-height: 62px;
            @media (min-width: 451px) {
                min-height: 112px;
            }
        }
        .btn{
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 0 1em;
            min-height: 40px;
            transition: all .4s;
            @media (min-width: 451px) {
                min-height: 58px;
            }
        }
        .button--frame{
            font-size: 1.071rem;
            padding: 0;
            // transition: all .6s;
            min-height: auto;
            position: relative;
            @media (min-width: 769px) {
                font-size: 1.25rem;
            }
        }
        .icon--arrow{
            width: 48px;
            @media (max-width: 950px) {
                width: 24px;
            }
        }
        a{
            display: block;
            // img:nth-of-type(1){
            //     visibility: hidden;
            // }
            .icon--arrow{
                position: absolute;
                right: 0;
                @keyframes arrow{
                    0%{
                        right: .6em;
                        opacity: 0;
                    }
                    50%{
                        right: .4em;
                        opacity: 1;
                    }
                    100%{
                        right: 0;
                        opacity: 0;
                    }
                }
            }
            &:hover{
                .card__mbr--thumb img{
                    // clip-path: inset(0 0 0 0);
                }
                .btn{
                    // background-color: #000;
                }
                .icon--arrow{
                    animation: arrow 1.2s infinite;
                }
                .svg--dark {
                    // fill: #fff;
                }
                .button--frame{
                    // color: #fff;
                }
                img{
                    // opacity: 0;
                    // visibility: hidden;
                }
                // img:nth-of-type(1){
                //     visibility: visible;
                // }
                // img:nth-of-type(2){
                //     opacity: 0;
                // }
            }
        }
    }
    &-work{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        @media (min-width: 769px) {
            grid-template-columns: repeat(3, 1fr);
        }
        &__item{
            border: 2px solid #000;
            margin-left: -2px;
            @media (max-width: 768px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: -1px;
                border-width: 1px;
            }
        }
        &--headline{
            background-color: var(--bg-green);
            border-bottom: 2px solid #000;
            text-align: center;
            padding: 32px 12px 32px;
            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-bottom: none;
                border-right: 1px solid #000;
                padding: 20px 10px 20px;
                width: 33.3333%;
                height: 100%;
                border-width: 1px;
                img{
                    width: 112px;
                }
            }
            .title{
                @extend %font_ja_m;
                transform-origin: top center;
                font-size: unquote('min(calc((2.7vw) * .95), calc((1.5rem) * .95))');
                letter-spacing: -0.02em;
                margin-top: .25em;
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((3.8vw) * .95), calc((1rem) * .95))');
                }
            }
        }
        &--text{
            padding: 30px 60px;
            @media (max-width: 768px) {
                flex: 1;
                padding: 15px;
            }
            .para--sm{
                font-size: unquote('min(calc((1.6vw) * .95), calc((0.916rem) * .95))');
                @media (max-width: 768px) {
                    font-size: unquote('min(calc((3.2vw) * .95), calc((0.857rem) * .95))');
                }
            }
        }
    }
}